.side-nav {
  height: 100vh;
  border-right: solid 1px var(--grayscale-hover);
  width: 230px;
  background-color: var(--gray-1);
  z-index: 1300;
  padding: 20px;

  .avatar-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .dashboard-group {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .dashboard-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}
