.root {
  position: fixed;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 2;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.column {
  padding: 1.5rem;
  white-space: nowrap;
}

.column > * {
  text-overflow: ellipsis;
}

.row .column:first-of-type {
  overflow: hidden;
}
