.stored-asset {
  .assetGroup {
    margin-bottom: 1em;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    &.isApproved {
      background-color: #edf7ed70;
    }

    &.isRfConsumable {
      border: 1px solid var(--safety);

      &.isCountApproved {
        border: 1px solid #e0e0e0;
        background-color: #edf7ed70;
      }
    }

    &.isExpired {
      border: 1px solid var(--error);
    }
    .approvals {
      padding: 0.5em;
    }

    .approved-by-text {
      font-weight: var(--font-weight-h1);
      color: #2e7d32;
      text-align: left;
    }

    .note {
      font-weight: var(--font-weight-h1);
      color: gray;
      text-align: left;
    }

    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      max-width: fit-content;
      padding: 0 0.4em;
    }

    .documented-images-container {
      padding: 10px 10px 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .images-container {
        display: flex;
        align-items: center;
      }

      .button-container {
        margin-left: auto;
      }
    }

    .status-bar {
      margin: 0.5em;
    }
  }
}
