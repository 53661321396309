.mobile-report-card-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  background-color: var(--light-blue-bg);
  width: 100%;
  border-radius: 20px;

  .header {
    padding: 13px 18px;
    background-color: var(--primary-light);
    border-start-start-radius: 20px;
    border-start-end-radius: 20px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .body {
    padding: 10px 18px 18px 18px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .detail-row {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .locations-chip {
      cursor: pointer;
      height: 20px;

      &:hover {
        background-color: var(--primary-light);
        transition: all 0.3s ease;
      }
    }
  }
}

.report-card-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  background-color: var(--light-blue-bg);
  width: 100%;
  border-radius: 10px;

  .header {
    padding: 13px 18px;
    background-color: var(--primary-light);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .body {
    padding: 10px 18px 10px 18px;
    display: flex;
    width: 100%;

    .detail-row {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .locations-chip {
      cursor: pointer;
      height: 20px;

      &:hover {
        background-color: var(--primary-light);
        transition: all 0.3s ease;
      }
    }
  }
}

.locations-pop-up {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .location {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 8px;
    border-bottom: 1px solid var(--grayscale-hover);
  }
}
