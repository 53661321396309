.image-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  .image-details {
    display: flex;
    gap: 10px;
    .image {
      display: flex;
      width: 75px;
      height: 75px;
      object-fit: cover;
      border-radius: 6px;
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: start;
      font-size: 12px;
      padding: 10px 5px 10px;

      .filename {
        font-size: 13px;
        font-weight: 500;
      }

      @media (max-width: 600px) {
        .filename {
          font-size: 10px;
        }
      }
    }
  }

  .delete-icon {
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: var(--grayscale);

    &.delete-icon:hover {
      color: var(--error);
      transition: all 0.3s ease;
    }
  }

  &.disabled {
    background: var(--grayscale-light);

    .delete-icon {
      color: var(--grayscale-disabled);

      &.delete-icon:hover {
        cursor: not-allowed;
        color: var(--grayscale-disabled);
      }
    }
  }
}

.image-preview-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .image-container {
    position: relative;
    width: 75px;
    height: 75px;
    border-radius: 6px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid var(--grayscale-light);
  }

  .delete-icon-container {
    position: absolute;
    cursor: pointer;
    background: var(--grayscale-light);
    border-radius: 50%;
    top: -5px;
    right: -5px;
    width: 30px;
    height: 30px;
    padding: 2px 2px 0 2px;
    border: 1px solid var(--grayscale);

    &.is-deleting {
      padding: 0px;
    }

    .delete-icon {
      color: var(--grayscale);

      &:hover {
        transition: color 0.3s ease;
        color: var(--error);
      }
    }

    &.disabled {
      color: var(--grayscale-light);
      .delete-icon {
        color: var(--grayscale);

        &:hover {
          color: var(--grayscale);
          cursor: not-allowed;
        }
      }
    }
  }
}
