.tray-procedure-edit-mode-organism_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  overflow: hidden;
  position: relative;

  .toggle-button {
    padding: 8px 16px;
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    width: auto;
    margin: 0;
  }

  button {
    background: var(--primary);
    border: none;
    color: white;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
    position: relative;

    &:hover {
      background: var(--primary-hover);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(98, 0, 234, 0.15);
    }

    &:active {
      transform: translateY(1px);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      transform: none;
    }

    svg {
      font-size: 20px;
    }
  }

  .back-button {
    position: absolute;
    top: 30px;
    right: 10px;
    transform: translate(-10px, 0px);
    rotate: 90deg;
    padding: 5px;
  }

  .joystick-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 30;

    > div {
      display: flex;
      justify-content: space-between;
      width: 120px;
    }
  }

  .tray-map_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vh - 130px);
    min-width: calc(100vh - 130px);
    margin-top: 130px;
    height: 100% !important;
    rotate: 90deg;
  }

  .zoom-controls {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 20px;

    button {
      rotate: 90deg;
    }

    .zoom-level {
      background: var(--primary);
      color: white;
      padding: 4px 12px;
      border-radius: 16px;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.3px;
    }
  }

  .control-buttons {
    position: absolute;
    top: 30px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 30;

    button {
      rotate: 90deg;
    }
  }

  .confirmation-popover {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 90%;
    max-width: 400px;

    .confirmation-popover-content {
      text-align: center;

      p {
        margin: 0 0 16px;
        color: var(--text-primary);
        font-size: 16px;
      }

      .confirmation-actions {
        display: flex;
        gap: 12px;
        justify-content: center;

        button {
          border-radius: 24px;
          width: auto;
          height: auto;
          padding: 10px 24px;
          font-weight: 500;
          letter-spacing: 0.3px;
          
          &.confirm-button {
            background: var(--primary);
          }
          
          &.cancel-button {
            background: var(--error);
          }
        }
      }
    }
  }

  .selection-actions {
    position: absolute;
    bottom: 50%;
    left: 40px;
    transform: translate(-50%, 40px);
    z-index: 30;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;

    .toggle-button {
      background: var(--primary);
      color: white;
      padding: 12px 24px;
      border-radius: 24px;
      font-weight: 500;
      letter-spacing: 0.3px;
      transition: all 0.2s ease;
      box-shadow: 0 2px 8px rgba(98, 0, 234, 0.15);
      width: auto;
      min-width: 200px;
      rotate: 90deg;

      &:hover {
        background: var(--primary-hover);
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(98, 0, 234, 0.2);
      }

      &:active {
        transform: translateY(1px);
      }
    }
  }

  .reference-panel-toggle {
    position: fixed;
    right: 0;
    top: 20px;
    transform: translateY(0%);
    background: var(--primary);
    color: white;
    border: none;
    border-radius: 24px;
    padding: 12px 20px;
    box-shadow: 0 2px 8px rgba(98, 0, 234, 0.15);
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 40;
    width: auto;
    height: auto;
    transition: right 0.3s ease;

    &.is-open {
      right: 300px;
      background: var(--primary-dark);
    }

    svg {
      font-size: 20px;
    }
  }

  .reference-panel {
    position: fixed;
    right: -300px;
    top: 0;
    width: 300px;
    height: 100%;
    background: white;
    box-shadow: -2px 0 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 35;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    visibility: hidden;
    overflow: hidden;

    &.is-open {
      right: 0;
      visibility: visible;
    }

    .reference-image-container {
      height: calc(100% - 80px);
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .reference-image {
        width: auto;
        height: auto;
        max-width: 268px;
        max-height: 268px;
        object-fit: contain;
        border-radius: 4px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        rotate: 90deg;
        transform-origin: center center;
        transition: transform 0.3s ease;
      }
    }

    .reference-panel-controls {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80px;
      padding: 16px;
      background: linear-gradient(
        to bottom,
        transparent,
        rgba(255, 255, 255, 0.95) 40%
      );
      display: flex;
      justify-content: center;
      gap: 24px;
      z-index: 2;

      button {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: var(--primary);
        border: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;
        rotate: 90deg;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &:not(:disabled):hover {
          background: var(--primary-hover);
          transform: translateY(-2px);
        }

        svg {
          font-size: 24px;
        }
      }
    }
  }

  @media (orientation: landscape) {
    flex-direction: row;
    padding: 20px;

    .back-button {
      position: absolute;
      top: 20px;
      left: 20px;
      transform: translate(0, 0);
      rotate: 0deg;
    }

    .joystick-container {
      top: auto;
      bottom: 20px;
      left: 20px;
      transform: translate(0, 0);
      rotate: -90deg;

      button {
        rotate: 0deg;
      }
    }

    .tray-map_wrapper {
      width: calc(100% - 140px);
      max-width: calc(100% - 140px);
      height: 100% !important;
      max-height: 100% !important;
      rotate: 0deg;
      margin-left: 140px;
      margin-top: 0px;
    }

    .zoom-controls {
      flex-direction: column;
      bottom: 20px;
      right: 20px;
      width: auto;

      button {
        rotate: 0deg;
      }
    }

    .control-buttons {
      top: 20px;
      right: 20px;
      left: auto;

      button:not(.back-button) {
        rotate: 0deg;
      }
    }

    .selection-actions {
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);

      .toggle-button {
        rotate: 0deg;
      }
    }

    .reference-panel {
      .reference-image-container {
        .reference-image {
          max-width: 100%;
          max-height: calc(100% - 32px);
          rotate: 0deg;
        }
      }

      .reference-panel-controls {
        button {
          rotate: 0deg;
        }
      }
    }

    .reference-panel-toggle {
      rotate: 0deg;
    }
  }

  @media (orientation: portrait) {
    .reference-panel {
      top: auto;
      bottom: -300px;
      right: 0;
      width: 100%;
      height: 300px;
      border-left: none;
      border-top: 1px solid rgba(255, 255, 255, 0.1);

      &.is-open {
        bottom: 0;
        right: 0;
      }

      .reference-image-container {
        height: 100%;

        .reference-image {
          max-width: calc(100% - 32px);
          max-height: 100%;
          rotate: 90deg;
        }
      }

      .reference-panel-controls {
        height: 100%;
        padding: 16px;
        width: auto;
        display: flex;
        justify-content: center;
        gap: 24px;
        position: absolute;
        right: 0;
        width: 80px;
        top: 0;
        bottom: 0;
        flex-direction: column;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.95), transparent);

        button {
          rotate: 90deg;
        }
      }
    }

    .reference-panel-toggle {
      top: auto;
      bottom: 0px;
      right: 10%;
      transform: translateX(50%);

      &.is-open {
        bottom: 300px;
        right: 10%;
      }
    }

    &.reference-panel-open {
      .tray-map_wrapper {
        height: calc(100% - 300px) !important;
        margin-bottom: 300px;
      }
    }
  }

  @media (hover: none) and (pointer: coarse) {
    button {
      -webkit-tap-highlight-color: transparent;
      
      &:focus {
        outline: none;
      }
      
      &:focus-visible {
        outline: none;
      }
    }
  }
}
