.tray-details {
  padding: 60px 12px 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 8px;

    .header_actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
    }

    .buttons_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .back_button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a43e9;
        border: none;
        background-color: white;
        margin-bottom: 20px;
      }

      .right_buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .delete {
          min-width: 30px;
          height: 30px;
        }
      }
    }
  }

  .tray-details_box {
    width: 100%;
    background-color: var(--primary-lightest);
    border: 1px solid var(--primary-light);
    border-radius: 8px;
    margin: 24px 0;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .tray-details_row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;

      button {
        margin: 10px 0px;
      }
    }

    .tray-details_right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .tray-details_updated {
      margin-top: 12px;
      display: flex;
      column-gap: 8px;
    }

    button {
      width: auto;
      margin-top: 10px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .sub-trays-table_container {
      width: 100%;

      .tray_img {
        width: 150px;
        height: 90px;
        border-radius: 15px;
      }

      .delete-sub-tray-button {
        min-width: 0px;
      }
    }
  }
}
