.dispositioned-asset-details-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  flex-wrap: nowrap;
  padding: 0 10px;

  .child-container-parent-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .child-countbox-label {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      max-width: 300px;

      .content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        
        .countbox {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.5em;
          height: 22px;
          padding: 0 0.4em;
          background-color: var(--gray-10);
        }

        .typography {
          margin-left: 0.5em;
          margin-right: 0.5em;
          text-transform: capitalize;
          opacity: 0.8;
          text-align: left;
        }

        .label_container {
          width: 100%;

          .sub-labels_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 3px;
          }
        }

        .divider {
          margin-left: 1em;
          margin-right: 1em;
        }
      }

      &.gap-1 {
        max-width: 180px;
      }
      &.gap-2 {
        max-width: 300px;
      }
      &.gap-3 {
        max-width: 400px;
      }
    }
  }

  .disabled {
    opacity: 0.5;
  }

  .children {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 2.5em;
    width: inherit;
  }

  .children.is-mobile {
    margin-top: 0.3em;
  }
}
