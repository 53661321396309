.tray-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  border: 1px solid transparent;
  aspect-ratio: 1;
  position: relative;
  overflow: hidden;
  padding: 0;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      var(--primary-lightest) 0%,
      transparent 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
  }

  // Accessibility focus styles
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--primary-light),
                0 8px 24px rgba(80, 53, 237, 0.15);
  }

  // Hover state
  &:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0 8px 24px rgba(80, 53, 237, 0.15);

    &::before {
      opacity: 0.6;
    }

    .tray-overlay {
      opacity: 1;
      transform: translateY(0);
    }

    .tray-image {
      transform: scale(1.05);
    }

    .arrow-icon {
      transform: translateX(4px);
      opacity: 1;
    }
  }

  // Active state
  &:active {
    transform: translateY(0);
    background-color: #f5f5f5;
  }

  .tray-data_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 0;
    position: relative;

    .tray-icon {
      width: 40px;
      height: 40px;
      object-fit: contain;
      flex-shrink: 0;
    }

    .arrow-icon {
      color: #5a43e9;
      font-size: 20px;
      opacity: 0.5;
      margin-left: auto;
      align-self: center;
      transition: all 0.2s ease-in-out;
    }

    .tray-data {
      flex: 1;

      .tray-data_name {
        font-size: 17px;
        font-weight: 500;
        color: #11142d;
        margin-bottom: 8px;
      }

      [data-field='Type'] {
        .field-value {
          text-transform: capitalize;
        }
      }
    }
  }

  .tray-image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    .tray-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  .tray-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      transparent,
      rgba(0, 0, 0, 0.7) 40%,
      rgba(0, 0, 0, 0.8)
    );
    padding: 16px;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 2;

    .tray-title {
      color: white;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 4px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .tray-type {
      color: rgba(255, 255, 255, 0.9);
      font-size: 0.75rem;
      font-weight: 500;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      margin-bottom: 8px;
    }

    .total-count {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.15);
      border-radius: 6px;
      backdrop-filter: blur(4px);

      .count-value {
        font-size: 1rem;
        font-weight: 600;
        color: var(--primary-lightest);
      }

      .count-label {
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }

  // Subtle shine effect
  &::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      45deg,
      transparent 0%,
      rgba(255, 255, 255, 0.1) 30%,
      transparent 100%
    );
    transform: rotate(45deg);
    animation: shine 3s infinite;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    z-index: 3;
  }

  &:hover::after {
    opacity: 1;
  }

  .status-badge {
    position: absolute;
    top: 12px;
    right: 12px;
    background: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.7rem;
    font-weight: 500;
    color: var(--primary-dark);
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 2;

    .status-dot {
      width: 6px;
      height: 6px;
      background: var(--success);
      border-radius: 50%;
      display: inline-block;
      animation: pulse 2s infinite;
    }

    &:hover {
      transform: translateY(-2px);
    }
  }

  .tray-stats {
    position: absolute;
    top: 12px;
    left: 12px;
    display: flex;
    gap: 6px;
    z-index: 2;

    .stat-item {
      background: var(--primary);
      padding: 4px 8px;
      border-radius: 8px;
      text-align: center;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        background: var(--primary-dark);
      }

      .stat-value {
        display: block;
        color: white;
        font-size: 0.9rem;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 2px;
      }

      .stat-label {
        display: block;
        color: rgba(255, 255, 255, 0.9);
        font-size: 0.6rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

@keyframes shine {
  0% {
    transform: rotate(45deg) translateX(-100%);
  }
  100% {
    transform: rotate(45deg) translateX(100%);
  }
}

// Optional: Add a subtle "Click to view details" hint
.tray-component::after {
  content: 'Click to view details';
  position: absolute;
  bottom: 8px;
  right: 16px;
  font-size: 12px;
  color: #666;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.tray-component:hover::after {
  opacity: 1;
}
