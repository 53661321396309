.selector-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .button {
    border: 1px solid var(--gray-2);
    border-radius: 6px;
    padding: 10px;
  }
  &.button:hover {
    background-color: var(--gray-2) !important;
  }

  .selector {
    display: flex;
    border-radius: 6px;
    border: 1px solid var(--gray-2);
    width: fit-content;

    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      cursor: pointer;
      user-select: none;
    }
    .option:first-child {
      border-right: 1px solid var(--gray-2);
      border-radius: 5px 0 0 5px;
    }
    .option:nth-child(2) {
      border-radius: 0 5px 5px 0;
    }

    .selected-yes {
      background-color: var(--green);
      color: white;
    }

    .selected-no {
      background-color: var(--red);
      color: white;
    }
  }

  button {
    .MuiButtonBase-root {
      background: red;
    }
  }
}
