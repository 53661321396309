.spd-cortex-video_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: inherit;

  .camera-select_container {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 30;

    .camera-switch {
      background: rgba(0, 0, 0, 0.75);
      backdrop-filter: blur(8px);
      border-radius: 50px;
      padding: 8px 16px;
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.2);
      transition: all 0.2s ease-in-out;

      &:hover {
        background: rgba(0, 0, 0, 0.9);
        transform: translateY(-1px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }

      &:active {
        transform: translateY(0);
      }

      .MuiButton-startIcon {
        margin-right: 8px;
      }
    }

    .camera-options {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      background: white;
      border-radius: 12px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
      min-width: 250px;
      overflow: hidden;

      .camera-option {
        padding: 12px 16px;
        cursor: pointer;
        transition: all 0.2s;
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
        }

        .label {
          font-weight: 500;
          color: #2c3e50;
        }

        .type {
          font-size: 12px;
          color: #6c757d;
        }

        &:hover {
          background: #f8f9fa;
        }

        &.active {
          background: var(--primary);

          .label,
          .type {
            color: white;
          }
        }
      }
    }
  }

  .format-switch_container {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--grayscale-dark);
    border-radius: 15px;
    z-index: 20;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
    padding: 10px;
  }

  .scanner_outline {
    width: 100px;
    height: 100px;
    border: 2px solid var(--white);
    box-sizing: border-box;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -65%);
  }

  .scanner_outline {
    width: 280px;
    height: 80px;
    border: 3px solid rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 8px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
}
