.assetFound {
  display: flex;
  border: 1px solid rgba(213, 213, 213);
}

.addedToList {
  align-self: center;
  color: #1d4622;
  display: flex;
  text-transform: uppercase;
}

.icon {
  color: #5b5b5b;
}

.secondaryText {
  opacity: 0.6;
}
