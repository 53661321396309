.load-spd-tray-map_container {
  min-height: 100vh;
  background-color: var(--background-default);
  position: relative;
}

.load-spd-tray-map_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px 32px 80px;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;

  &::after {
    content: '';
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%236b7280'%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    animation: bounce 2s infinite;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    z-index: 10;
    backdrop-filter: blur(4px);
  }

  &.is-scrollable::after {
    opacity: 1;
  }

  &::before {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(
      to top,
      var(--background-default) 20%,
      rgba(var(--background-default-rgb), 0.8) 60%,
      transparent
    );
    pointer-events: none;
    z-index: 5;
  }

  .mode-banner {
    width: 100%;
    padding: 24px 32px;
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    font-size: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;

    &.map-mode {
      background-color: var(--primary-lightest);
      border: 1px solid var(--primary-light);
      color: var(--text-primary);

      .instruction-text {
        white-space: pre-line;
        line-height: 1.6;
        
        .instruction-header {
          font-weight: 600;
          color: var(--primary);
          display: block;
          margin-bottom: 8px;
        }
      }
    }

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
    }
  }

  .tray-map-container {
    width: 100%;
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  .buttons_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 16px;

    .action-button {
      min-width: 200px;
      height: 48px;
      font-weight: 500;
      border-radius: 8px;
      text-transform: none;
    }
  }
}

.confirm-image-modal {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h2 {
    font-size: 24px;
    font-weight: 500;
    color: var(--text-primary);
  }

  p {
    font-size: 16px;
    color: var(--text-secondary);
    text-align: center;
  }

  .button-container {
    display: flex;
    gap: 16px;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-10px);
  }
  60% {
    transform: translateX(-50%) translateY(-5px);
  }
}
