.spd-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .stepper,
    .stepper_content {
      width: 100%;
      max-width: 1280px;
    }
    
    .stepper {
      margin: 0 auto;
      padding: 3rem 0;

      .MuiSvgIcon-root text {
        fill: var(--white) !important;
      }

      .step_label {
        font-weight: 500;
      }

      .step-label-container {
        color: var(--grayscale-dark);
        cursor: pointer;
      }
    }
  }
}
