.filters-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 12px;
        padding: 10px 30px;
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 10px 30px;
    }

    .input-container {
        width: 200px;
    }
}

.filters-container.is-tablet {
    .input-container {
        width: 100%;
    }
}