.location-management-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .columns-container {
    padding: 20px;
    margin: 0 20px 0px 20px;
    height: calc(100vh - 150px);
    display: flex;
    align-items: start;
    gap: 15px;
    min-width: 900px;
    overflow: auto;

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 325px;
      gap: 15px;
      padding: 5px 15px;
      border-radius: 10px;
      background: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      overflow: auto;

      .column-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 10px;
        border-bottom: 1px solid lightgray;
        font-size: 1.1rem;
        font-weight: 600;

        .column-actions {
          display: flex;
          gap: 10px;

          .add-icon {
            cursor: pointer;
            color: var(--primary);
            font-size: 1.2rem;
            transition: 0.3s;
            background-color: transparent;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }

      .column-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .row {
          cursor: pointer;
          padding: 12px 15px;
          background: var(--primary-light);
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          font-weight: 500;
          animation: slideIn 0.2s ease-in;
          transition: 0.2s;

          &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          }
        }
        .row.selected {
          box-shadow: 0 0 10px var(--primary);
          border: 0.01px solid var(--primary);
        }
        .row.unselected {
          background: var(--gray-2);
          color: var(--gray-5);
        }
      }

      &.is-dragging-disabled {
        transition: all 0.2s;
        background: rgb(254, 196, 196);
      }
    }
    .column::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 48px;
      background: linear-gradient(to top, white, transparent);
      pointer-events: none;
      border-radius: 0 0 10px 10px;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 95vw;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
