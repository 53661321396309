.confirmation-countdown_modal {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-height: 95%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 1em;
  border-radius: 8px;

  .workflow-selection_button {
    width: 45% !important;
  }

  .header-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    text-transform: capitalize;
  }

  .subheader-box {
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    align-items: center;
    margin-left: 3em;
  }

  .subheader-box.isMobile {
    margin-left: 1em;
  }

  .child-box {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    max-height: 70%;
  }

  .button-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
  }

  .analyze-state {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: column;
    justify-content: center;
  }
}
