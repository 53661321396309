.add-location-modal-container {
  background: white;
  width: 500px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgb(161, 161, 161);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background: var(--primary-light);
    padding: 20px;
  }
  .body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .input-container {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .label {
        font-size: 1rem;
        font-weight: 600;
      }

      .input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid lightgray;
        outline: none;
        transition: 0.3s;

        &:focus {
          border: 1px solid var(--primary);
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      .button {
        padding: 10px 20px;
        border-radius: 5px;
        background: var(--primary);
        color: white;
        font-weight: 600;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background: var(--primary-dark);
        }
      }
    }
  }
}
