.confirm-tray-image-modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 500px;
  max-height: 95%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 1em;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;

  .header-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    text-transform: capitalize;
  }

  .tray_image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
  }

  .description {
    text-align: center;
    color: var(--text-secondary);
  }

  .button-container {
    display: flex;
    gap: 16px;
    justify-content: center;
    width: 100%;
  }

  .camera-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .camera-select_container {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 200px;
      border-radius: 5px;

      .camera-options_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      .camera-options {
        width: 200px;
        background-color: var(--primary-lightest);
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 99999;
        margin-top: 15px;

        .camera {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 5px;
          border-radius: 10px;

          .label {
            font-size: 13px;
            font-weight: 500;
            width: 100%;
            text-align: left;
            margin-bottom: 5px;
          }

          .type {
            font-size: 12px;
            font-weight: 400;
            width: 100%;
            text-align: left;
          }
        }
      }

      button {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
      }
    }

    .capture {
      position: absolute;
      bottom: 50%;
      right: 5px;
      border-radius: 50%;
      transform: translate(-5px, 50%);
      padding: 10px;
      background-color: var(--primary);
      color: white;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
    }

    .feed-container {
      width: 100%;

      .skeleton {
        position: absolute;
        pointer-events: none;
        width: 100%;
        height: 300px;
        border-radius: 15px;
      }
    }

    .feed-container.hidden {
      display: none;
    }

    .feed {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 15px;
    }

    .button-container {
      margin-top: 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      button {
        width: auto;
      }
    }
  }

  .selection-choice_button {
    margin: 10px 0;
  }

  .bottom-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    button {
      height: 100%;
      white-space: normal;
    }
  }

  .MuiStepper-root {
    margin-bottom: 20px;
  }

  .MuiStepIcon-text {
    fill: white;
  }

  .confirmation-step {
    width: 100%;

    .confirmation-details {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .surgeries-step {
    width: 100%;
    height: auto;
    overflow-y: hidden;
  }
}
