.selected-tray-modal-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  max-width: 500px;
  max-height: 95%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 1em;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;

  .header-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    text-transform: capitalize;

    img {
      width: 25px;
      height: 25px;
    }
  }

  .product-container {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .screws_container {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    border-radius: 15px;
    max-height: 300px;
    overflow-y: auto;
  }

  .buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    column-gap: 1em;
  }
}
