.reps-container {
  background: white;
  border-radius: 5px;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}
