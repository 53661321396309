.tray-visualization-confirm-changes_modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  max-width: 95%;
  max-height: 95%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 1em;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 25px;
  overflow-y: auto;

  .header-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    text-transform: capitalize;
  }

  .buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1em;
  }
}
