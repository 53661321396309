.drawer-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
}

.drawer-content {
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 6rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  .container {
    padding: 1rem;
    background-color: #ffffff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 10000;

    .drag-bar {
      margin-left: auto;
      margin-right: auto;
      width: 48px;
      height: 5px;
      flex-shrink: 0;
      border-radius: 30px;
      background-color: #d1d5db;
      margin-bottom: 2rem;
      cursor: grab;
    }

    .drag-bar:hover {
      box-shadow: 0px 0px 10px 0px var(--grayscale-hover);
    }

    .content-wrapper {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .drawer-title {
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }

  &.is-half-width {
    width: 50%;
    margin: auto;
  }
}
