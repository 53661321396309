.header-section {
  padding: 0px 16px;
  margin-bottom: 20px;
  text-align: left;
  max-width: 210mm;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
  }

  hr {
    margin: 12px 0;
    border-bottom: 2px solid black;
  }

  .info-columns {
    display: flex;
    justify-content: space-between;

    .column {
      flex: 1;
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }

      p {
        font-size: 1rem;
        margin: 6px 0;

        strong {
          font-weight: 600;
          color: #333;
        }

        span {
          color: #555;
        }

        &.highlight {
          font-size: 1.2rem;
          margin-bottom: 15px;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
