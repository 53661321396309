.media-upload-modal-container {
  height: fit-content;
  .hidden-input {
    display: none;
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0 20px;
    padding-top: 5px;
    // max-height: 30vh;
    // overflow-y: auto;
  }

  .empty-state {
    margin: 30px 0 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .attach-button {
    margin-bottom: 10px;
  }

  .actions {
    width: calc(100% - 32px);
    margin-bottom: 54px;
    position: fixed;
    bottom: 0;
    background: var(--primary-lightest);
    padding-top: 10px;
  }

  &.is-mobile {
    .images {
      margin: 20px 0 80px;
      max-height: 50vh;
      overflow-y: auto;
    }
  }
}
