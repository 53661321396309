.review-step {
  .review-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;

    .sub-tray-item {
      display: flex;
      gap: 1rem;
      margin: 1rem 0;
      padding: 1rem;
      border: 1px solid #eee;
      border-radius: 8px;

      .sub-tray-item_description {
        color: var(--grayscale-contrast-text);
        font-weight: 500;
      }

      img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .inventory-sheets-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 1rem;
      margin-top: 1rem;

      .inventory-sheet-item {
        aspect-ratio: 1;
        border: 1px solid #eee;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          text-decoration: none;
          color: #1976d2;
          text-align: center;
          padding: 1rem;
        }
      }
    }
  }
}

.buttons_container {
  // grid-template-columns: 1fr 2fr 2fr !important;
}
