.product-registration-container {
  background-color: var(--primary-lightest);
  margin: 1em 0;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0.75em;

  .subtitle {
    margin: 2.5px 0;
    color: rgba(0, 0, 0, 0.6);
  }

  .successMessage {
    align-self: center;
    color: var(--success-dark);
    display: flex;
    text-transform: uppercase;
  }

  .formControl {
    margin-top: 0;
  }

  .products-container {
    max-height: 300;
    overflow: 'auto';
    background: white;
    border-radius: 1;
  }
}
.product-registration-loader {
  width: 100%;
  display: flex;
  justify-content: center;
}
