.image-preview-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background: var(--grayscale-light);
  border-radius: 4px;
  max-width: 82%;
  max-height: 82vh;
  gap: 10px;

  .image-preview {
    max-width: 100%;
    max-height: 75vh;
    border-radius: 4px;
    object-fit: cover;
  }
}
