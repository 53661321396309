.edit-tray_wrapper {
  padding: 60px 12px 0;

  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow-y: auto;

  .header {
    padding: 16px 0 8px;
    width: 100%;

    .back_button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5a43e9;
    }
  }

  .edit-tray_header {
    width: 100%;
    background-color: var(--primary-lightest);
    border: 1px solid var(--primary-light);
    border-radius: 8px;
    margin: 24px 0;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left_container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;

      .tray-details_detail {
        display: flex;
        align-items: center;
        gap: 4px;

        strong {
          margin-left: 4px;
        }
      }

      .back_button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a43e9;
        border: none;
        background-color: white;
        margin-bottom: 20px;
        align-self: flex-start;
      }
    }

    .right_container {
      width: 150px;
      height: 90px;

      img,
      .skeleton {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        object-fit: cover;
      }
    }
  }

  .error {
    color: var(--error);
  }

  .tray-content-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
  }

  .map_container {
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    
    .map-selector-container {
      width: 100%;
    }

    .map-selector-container.disabled {
      pointer-events: none;
    }
  }

  .buttons-container {
    width: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    button {
      width: 90%;
    }

    .left-container {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .load-mode-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
      }
    }

    .right-container {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }

  .tray-contents-section {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding: 24px;
    border-radius: 8px;

    .MuiAccordion-root {
      width: 100%;
      background-color: var(--grayscale-lightest);
      border: 1px solid var(--grayscale-light);
      border-radius: 8px !important;

      &::before {
        display: none;
      }

      .MuiAccordionSummary-root {
        padding: 0 16px;
      }

      .accordion-summary {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
      }
    }

    .tray-contents-items {
      gap: 12px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));

      .sub-tray-plate_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        border-radius: 5px;
        border: 1px solid var(--grayscale-light);
        margin-bottom: 10px;
        background-color: white;

        .qr-code {
          font-size: 35px;
        }

        .content {
          width: calc(100% - 50px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: left;
        }
      }

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  }

  .mode-banner {
    width: 100%;
    padding: 12px 24px;
    margin: 24px 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;

    &.click-mode {
      width: 100%;
      background-color: var(--primary-lightest);
      border: 2px solid var(--primary-light);
      color: var(--primary);
    }

    &.scan-mode {
      width: 100%;
      background-color: #FFF3E0;
      border: 2px solid #FFB74D;
      color: #E65100;
    }
  }
}
