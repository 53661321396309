.tray-visualization_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 20px;
  
  .tray-visualization {
    width: 85%;
    aspect-ratio: 2 / 1 !important;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 15px;
    border: 35px solid var(--gray-9) !important;
    border-top: none;
    background-color: var(--gray-9);

    .header {
      width: 100%;
      height: 7%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;

      .title {
        font-size: 22px;
        font-weight: 800;
        color: var(--primary-lightest);
        border-radius: 30px;
        padding: 5px 20px;
      }

      .title.measurements {
        background-color: #ff6e3b;
      }
    }

    .transform-component_wrapper {
      padding: 0px;
      height: calc(100% - 10%);
      margin: auto !important;
      align-self: center !important;
      justify-self: center;
      width: 100%;

      .transform-component_content {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: center;

        .screw-area_container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          border-radius: 15px;
          background-color: var(--gray-3);

          .screw-area {
            width: 30%;
            height: 50%;
            background-color: var(--gray-3);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .label {
              font-size: 8px;
              color: black;
              font-weight: 500;
              padding: 2px 5px;
              background-color: var(--white);
              border-radius: 30px;
              margin-bottom: 10px;
            }

            .column-sizes_wrapper {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              margin: 1px;
              padding: 1;

              .column-size_container {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: var(--white);
                margin: 1px;
                cursor: pointer;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                .size {
                  font-size: 8px;
                  color: black;
                  font-weight: 500;
                  background-color: var(--white);
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 30px;
                  padding: 0px !important;
                }
              }
            }

            .screws-row {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              margin: 1px;
              padding: 0;

              .screw {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                margin: 1px;
                cursor: pointer;
                padding: 0;
                background-color: black;
                border: 1px solid var(--gray-3);
              }
              .screw.loaded.drilling {
                background-color: var(--screw-drilling);
              }

              .screw.loaded.emergency {
                background-color: var(--screw-emergency);
              }

              .screw.loaded.bone {
                background-color: var(--screw-bone);
              }

              .screw.loaded {
                border: 0.5px solid black;
              }

              .screw.implanted,
              .screw.wasted {
                background-color: black !important;
              }

              .screw.implanted {
                border: 0.5px solid black;
              }

              .screw.wasted {
                border: 1px solid var(--error);
              }

              .screw.disabled {
                border: 0.5px solid var(--grayscale-disabled);
                background-color: var(--grayscale-dark);
              }

              .screw.loaded.selected,
              .screw.selected {
                background-color: var(--screw-highlight) !important;
                animation: neon 0.5s infinite alternate;
              }

              .screw {
                display: flex;
                align-items: center;
                justify-content: center;

                .not-allowed_icon {
                  width: 100%;
                  height: 100%;
                  background-color: var(--grayscale-disabled);
                }
              }

              .screw-data_container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                text-align: left;

                .description {
                  margin-bottom: 10px;
                }

                .details {
                  margin-bottom: 5px;
                }
              }
            }
          }
          .emergency.top {
            border-top-right-radius: 15px;
          }
          .emergency.bottom {
            border-bottom-right-radius: 15px;
          }
          .bone.top {
            border-top-left-radius: 15px;
          }
          .bone.bottom {
            border-bottom-left-radius: 15px;
          }
        }
      }
    }
  }

  .tray-visualization.with-plates {
    .transform-component_wrapper {
      height: 100%;

      .screw-area_container {
        width: 40%;

        .screw-area {
          width: 45%;
        }

        .screw-area.emergency {
          width: 20%;
        }
      }
    }
  }

  .save-and-load-next_button {
    width: auto;
  }

  @media screen and (max-width: 780px), screen and (orientation: landscape) {
    .tray-visualization.is-mobile {
      width: 100% !important;
      height: 350px;
      max-height: 350px;
      border-width: 15px !important;

      .screw-area_container {
        .screw-area {
          .label {
            font-size: 3px !important;
            padding: 1px 2px !important;
          }
        }

        .column-size_container {
          width: 5px !important;
          height: 5px !important;

          .size {
            font-size: 3px !important;
          }
        }

        .screws-row {
          .screw {
            width: 5px !important;
            height: 5px !important;
          }
        }
      }
    }
  }
}
