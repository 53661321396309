.report-detail {
  display: flex;
  flex-direction: column;

  .value {
    gap: 5px;
  }
  .title {
    font-size: 9px;
    color: var(--gray-9);
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}
