.guest-info-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    min-height: 100vh;
    background-color: #f0f0f0;
    padding-top: 50px; 

    .guest-info-form {
        width: 600px; 
        padding: 30px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        .form-title {
            text-align: center;
            margin-bottom: 30px;
            font-size: 24px; 
        }

        form {
            display: flex;
            flex-direction: column;

            .form-input {
                margin-bottom: 20px; 

               
                input {
                    font-size: 16px;
                }
            }

            .button-container {
                display: flex;
                justify-content: center;
                margin-top: 30px;

                .submit-button {
                    width: 100%;
                    padding: 12px 0; 
                    font-size: 18px; 
                }
            }
        }
    }
}