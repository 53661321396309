.add-procedure-container {
  padding: 20px;
  // background: var(--primary-light);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 30px 0;

    .back-icon {
      height: 35px;
      width: 35px;
    }
  }

  .section {
    margin: 15px 0 30px 0;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .row {
      display: flex;
      align-items: center;
      gap: 5px;

      .admit-button {
        text-transform: capitalize;
        margin-top: 3px;
        padding-inline: 0px;
      }
    }
  }
}
