.media-upload-title {
  margin-left: 15px;
  color: var(--primary);
}
.media-upload-container {
  border: 2px dashed var(--primary-light);
  border-radius: 5px;

  .images {
    padding: 7px 7px 0 7px;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    margin: 0px 0 0px;

    &.has-images.has-actions {
      margin-bottom: 30px;
    }
  }

  &.view-only {
    margin: 5px;
    .images {
      margin-bottom: 7px;
    }
  }

  &.is-loading {
    border: none;
    .images {
      padding: 0;
      margin: 0;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 7px 7px 7px;
  }
}

.media-upload-loading {
  padding: 0px 0px;
  border-radius: 4px;

  .loading-progress {
    border-end-end-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}
