.inventory-sheets-step {
  .inventory-sheets-step_content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }

  .camera-section {
    .camera-container {
      width: 100%;
      aspect-ratio: 16/9;
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      background: #000;
      margin-bottom: 1rem;

      .camera-select_container {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 2;

        .camera-menu_button {
          background: rgba(0, 0, 0, 0.5);
          color: white;
          min-width: unset;
          padding: 8px;
          border-radius: 50%;

          &:hover {
            background: rgba(0, 0, 0, 0.7);
          }
        }

        .camera-options {
          position: absolute;
          top: 100%;
          left: 0;
          margin-top: 0.5rem;
          background: white;
          border-radius: 4px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          padding: 0.5rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          min-width: 200px;

          .camera {
            padding: 0.5rem;
            cursor: pointer;
            border-radius: 4px;

            &:hover {
              background: #f5f5f5;
            }

            &.active {
              background: #e3f2fd;
            }

            .camera-text {
              color: #333;
            }
          }
        }
      }

      .capture-photo_button {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        z-index: 2;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        min-width: unset;
        width: 48px;
        height: 48px;
        padding: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: rgba(0, 0, 0, 0.7);
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .feed {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .file-attachment_container {
      display: flex;
      justify-content: center;

      input[type='file'] {
        display: none;
      }
    }
  }

  .inventory-sheets-section {
    .empty-state {
      text-align: center;
      color: #666;
    }

    .sheets-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1rem;

      .inventory-sheet_item {
        position: relative;
        aspect-ratio: 1;
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;

        img,
        a {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }

        .loading_container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(255, 255, 255, 0.8);
        }

        .delete_button {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          min-width: unset;
          width: 24px;
          height: 24px;
          padding: 0;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.5);
          color: white;

          &:hover {
            background: rgba(0, 0, 0, 0.7);
          }

          .delete_icon {
            font-size: 18px;
            line-height: 1;
          }
        }
      }
    }
  }

  .buttons_container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
}
