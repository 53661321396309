.filter-button-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .filter-chip {
    background-color: var(--grayscale-lightest);
    font-weight: 500;
    margin: 1px;
  }
}
