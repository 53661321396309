.asset-details-card-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  .asset-details-card-box-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 10px 10px 0px;

    .left-side {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 10px 0;
    }
  }

  .asset-card-children {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &.is-mobile {
    .asset-details-card-box-row {
      .left-side {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }
  }

  &.has-reps {
    padding-bottom: 0.5rem !important;
  }
}
