.summary {
  background: var(--light-blue-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  border-radius: 12px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
}
.summary.is-tablet {
  padding: 20px 10px;
}
