.container {
  direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.icon {
  margin-right: 5px;
}

.typography {
  color: white;
  font-weight: 500;
}
