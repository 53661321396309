.tray-id-step {
  .tray-id-step_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 800px;
    margin: 2rem auto;
  }

  .scanner-container {
    width: 100%;
    aspect-ratio: 16/9;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: #000;
  }

  .next-button {
    margin-top: 1rem;
  }
}
