.add-missing-fields-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 10px 0 20px;
  .MuiFormHelperText-sizeMedium {
    background: var(--primary-lightest);
    padding: 2px 8px;
    margin: 0;
  }
}
