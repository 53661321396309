.header_wrapper {
  margin-bottom: 32px;
}

.analyze-tray {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 640px;
  margin: 0 auto;

  .camera-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .camera-container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    width: 640px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;

    .tray-type-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.85);

      .overlay-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 25;
        text-align: var(--grayscale);
        font-size: 1rem;

        &.warning {
          font-weight: bold;
          color: var(--warning);
        }
      }
    }

    .camera-toggle-btn {
      position: absolute;
      width: 45px;
      height: 45px;
      top: 8px;
      left: 8px;
      border-radius: 25px;
    }

    .capture-btn {
      position: absolute;
      top: 45%;
      right: 8px;
      width: 45px;
      height: 45px;
      border-radius: 25px;
    }

    .tray-image {
      aspect-ratio: 16/9;
      max-width: 100%;
      padding-left: 80px;
      padding-right: 80px;
      width: auto;
      height: auto;
      object-fit: cover;
    }

    .camera-feed {
      aspect-ratio: 16/9;
    }
  }

  .camera-options {
    position: absolute;
    width: 400px;
    background-color: var(--primary-lightest);
    border-radius: 10px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;

    .camera {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      text-align: left;
      background-color: var(--primary-light);
      color: var(--primary-contrast-text);
      padding: 8px 4px;
    }

    .camera.active {
      background-color: var(--primary);
      color: white;
    }
  }

  .par-level_container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.25rem;

    .par-level_label {
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 640px;
    margin: 0 auto;

    .recapture-btn {
      width: 40%;
    }

    .analyze-btn {
      width: 60%;
    }
  }

  .confirm-btn {
    width: 640px;
    margin: 0 auto;
  }
}

.tray-analysis-dialog {
  .MuiDialog-paper {
    height: 95vh;
    max-width: 95vw;
    margin: 2.5vh auto;
    background-color: var(--grayscale-lightest);
    padding: 10px 20px;
  }
}
