.referring-physicians-main-container {
  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 8px 10px 15px;

    .input-container {
      padding: 0 15px 0 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      &.flex-end {
        justify-content: flex-end;
      }

      .search-add {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        &.hide {
          display: none;
        }

        .input {
          width: 100%;
          max-width: 768px;
        }

        .add-physician-button {
          max-width: fit-content;
          height: 56px;
        }
      }

      .send-email-button {
        max-width: fit-content;
        height: 56px;
      }
    }

    .no-data {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.checkbox-header-referring-physicians {
  padding-right: 50px;
}

.actions-referring-physicians {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  &.checkbox {
    padding-right: 50px;
  }
}

@media screen and (max-width: 768px) {
  .referring-physicians-main-container .content {
    .input-container {
      flex-direction: column;
      gap: 0.5rem;
      .search-add {
        width: 100%;
        flex-direction: column;
        gap: 0.5rem;
        .add-physician-button {
          max-width: 100%;
          height: unset;
        }
      }
      .send-email-button {
        max-width: 100%;
        height: unset;
      }
    }
    .send-bulk-email-header {
      flex-direction: column;
      gap: 10px;
      height: unset;
    }
  }
}
