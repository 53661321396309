.list-of-surgeries_container {
  width: 100%;
  max-height: 500px;
  border-radius: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  .date-picker_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }

  .hidden {
    display: none;
  }
}
