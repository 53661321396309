.inventory-report-container {
  display: flex;
  flex-direction: column;
  gap: 7px;

  .inventory-header-container {
    display: flex;
    align-items: center;
    margin: 10px 40px 0;
    gap: 10px;
    flex-wrap: wrap;
  }

  .error {
    width: 100%;
  }

  .loader {
    height: calc(100vh - 250px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .infinite-scroll-loader {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .report-cards {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    margin: 10px 30px 20px;
  }
}