.paginated-search-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 10px 0;

    .icon-container {
      border: 2px solid var(--primary);
      padding: 9px;
      border-radius: 5px;
      .back-icon {
        height: 35px;
        width: 35px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .result {
    overflow-y: auto;
    height: 80svh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
    padding-bottom: 200px;

    .item {
      all: unset;
      display: flex;
      padding: 15px 20px;
      border: 1px solid var(--grayscale-light);
      border-radius: 6px;
      cursor: pointer;
      margin-left: 0px;

      &:hover {
        transition: all 0.2s ease;
        box-shadow: 0px 4px 10px rgba(10, 10, 10, 0.1);
      }

      &.is-child {
        background-color: var(--grayscale-light);
        margin-left: 20px;
      }
    }

    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .infinite-scroll-loader {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.is-tablet {
      height: 65dvh;
    }
    &.is-mobile {
      padding-bottom: 0px;
    }
  }
}

.manual-add-search-modal {
  position: absolute;
  bottom: 0;
  padding: 40px 35px 0;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  background-color: white;
  height: 90%;
  width: 50%;
  min-width: 500px;

  &.is-mobile {
    width: 100%;
    height: 100%;
    padding: 10px 5px 0;
    min-width: 100%;
  }
}
