.loading-container {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-buttons {
  .back_button {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1000;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.camera-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;

  // To hide HTML5 video control button on mobile
  video::-webkit-media-controls-overlay-play-button {
    display: none !important;
  }

  video::-webkit-media-controls {
    display: none !important;
  }

  *::-webkit-media-controls-panel {
    display: none !important;

    -webkit-appearance: none;
    appearance: none;
  }

  *::--webkit-media-controls-play-button {
    display: none !important;

    -webkit-appearance: none;
    appearance: none;
  }

  *::-webkit-media-controls-start-playback-button {
    display: none !important;

    -webkit-appearance: none;
    appearance: none;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .camera-feed {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    background-color: #000;
  }

  .buttons-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    .camera-options_container {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 30;

      .camera-switch {
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(8px);
        border-radius: 50px;
        padding: 8px 16px;
        color: var(--primary);
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: 500;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

        &:hover {
          background: white;
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }

        &:active {
          transform: translateY(1px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .MuiButton-startIcon {
          margin-right: 8px;
        }
      }

      .camera-options {
        animation: slideDown 0.2s ease-out;
        transform-origin: top center;
        
        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateX(-50%) translateY(-10px);
          }
          to {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
          }
        }

        position: absolute;
        top: calc(100% + 8px);
        left: 50%;
        transform: translateX(-50%);
        background: white;
        border-radius: 12px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        min-width: 250px;
        overflow: hidden;

        .camera-option {
          padding: 12px 16px;
          cursor: pointer;
          transition: all 0.2s;
          display: flex;
          flex-direction: column;
          gap: 4px;
          border-bottom: 1px solid #eee;

          &:last-child {
            border-bottom: none;
          }

          .label {
            font-weight: 500;
            color: #2c3e50;
          }

          .type {
            font-size: 12px;
            color: #6c757d;
          }

          &:hover {
            background: #f8f9fa;
          }

          &.active {
            background: var(--primary);

            .label,
            .type {
              color: white;
            }
          }
        }
      }
    }

    .flip {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-lightest);
      border-radius: 50%;
      border: none;
      background-color: var(--primary);
      padding: 10px;
      margin-bottom: 20px;

      .icon {
        width: 35px;
        height: 35px;
      }
    }

    .capture-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 32px 40px;
      background: linear-gradient(to top, 
        rgba(0, 0, 0, 0.75) 0%,
        rgba(0, 0, 0, 0.5) 50%,
        transparent 100%
      );
      z-index: 20;

      .side-button {
        width: auto;
        height: 45px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(8px);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;
        border: 2px solid rgba(255, 255, 255, 0.8);
        color: white;

        &:hover {
          background: rgba(255, 255, 255, 0.3);
          transform: translateY(-2px);
        }

        &:active {
          transform: translateY(1px);
        }

        .icon {
          width: 24px;
          height: 24px;
        }
      }

      .capture {
        width: 76px;
        height: 76px;
        border-radius: 50%;
        background: transparent;
        position: relative;
        transition: all 0.2s ease;
        border: 4px solid white;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          height: 80%;
          border-radius: 50%;
          background: white;
          transition: all 0.2s ease;
        }

        &:hover {
          transform: scale(1.05);
          box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
        }

        &:active {
          transform: scale(0.95);
          &::before {
            width: 75%;
            height: 75%;
          }
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 24px;
          height: 24px;
          color: var(--primary);
          z-index: 1;
        }
      }

      .manual-add {
        padding: 10px 20px;
        border-radius: 24px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(8px);
        color: white;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.3px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        transition: all 0.2s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.3);
          transform: translateY(-2px);
        }

        &:active {
          transform: translateY(1px);
        }
      }
    }
  }
}
