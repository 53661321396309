.confirm-screw-selections-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: var(--primary-lightest);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  padding: 1em;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;
  z-index: 20000;

  &.floating {
    position: fixed;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    animation: slideDown 0.3s ease-out;
  }

  .header-box {
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--primary-contrast-text);
  }

  .description {
    font-size: 0.95rem;
    line-height: 1.5;
    text-align: center;
  }

  .button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .MuiDialog-paper {
    min-width: 400px;
    max-width: 600px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    overflow: visible;
  }

  .MuiDialogTitle-root {
    padding: 24px 32px 16px;
    background: linear-gradient(to right, var(--primary), var(--primary-dark));
    color: white;

    .modal-title {
      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
  }

  .MuiDialogContent-root {
    padding: 24px 32px;

    .modal-text {
      margin: 0;
      color: var(--grayscale-darkest);
      font-size: 1.1rem;
      line-height: 1.6;
      text-align: center;
    }
  }

  .MuiDialogActions-root {
    padding: 16px 32px 24px;
    display: flex;
    gap: 16px;
    justify-content: center;

    button {
      min-width: 140px;
      height: 44px;
      font-size: 1rem;
      font-weight: 500;
      text-transform: none;
      border-radius: 8px;

      &.cancel-button {
        color: var(--primary);
        border-color: var(--primary);

        &:hover {
          background-color: rgba(90, 67, 233, 0.04);
        }
      }

      &.confirm-button {
        background-color: var(--primary);

        &:hover {
          background-color: var(--primary-dark);
        }
      }
    }
  }

  // Add subtle animation for modal appearance
  .MuiDialog-paper {
    animation: modalFadeIn 0.2s ease-out;
  }

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

// Media query for smaller screens
@media (max-width: 768px) {
  .confirm-screw-selections-modal {
    &.floating {
      width: calc(100% - 48px);
      max-width: 400px;
    }
  }
}
