.sub-trays-step {
  .header_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .sub-trays-actions {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: flex-end;

    button {
      min-width: fit-content;
    }
  }

  .header_container {
    margin-bottom: 16px;
  }

  .sub-trays-table_container {
    width: 100%;
    max-height: 65vh;

    .tray_img {
      width: 180px;
      height: 90px;
      border-radius: 15px;
      object-fit: cover;
    }
  }

  .sub-trays-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
}
