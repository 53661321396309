.filter-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;

  .filter-bar-container-left {
    display: flex;
    align-items: center;

    .pdf-settings-button {
      padding: 5px 15px;
    }
  }

  .filter-bar-container-right {
    padding-top: 10px;
  }
}

.filter-bar-container.is-mobile {
  flex-direction: column;
  padding: 1rem;
}
