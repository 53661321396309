.root {
  display: flex;
  position: relative;
}

/* Compensate for BottomNavigation + border */
.hasBottomNavigation {
  margin-bottom: 57px;
}

.navigationWrapper {
  display: flex;
  justify-content: center;
}

.bottomNavigation {
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(213, 213, 213);
}

.tab {
  display: none;
}

.tabIsActive {
  display: block;
}

.videoContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.video {
  object-fit: cover;
}
