.HoverCardContent {
  border-radius: 6px;
  padding: 20px;
  width: 300px;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.HoverCardContent[data-side='top'] {
  animation-name: hoverCardSlideDownAndFade;
}
.HoverCardContent[data-side='right'] {
  animation-name: hoverCardSlideLeftAndFade;
}
.HoverCardContent[data-side='bottom'] {
  animation-name: hoverCardSlideUpAndFade;
}
.HoverCardContent[data-side='left'] {
  animation-name: hoverCardSlideRightAndFade;
}

.HoverCardArrow {
  fill: white;
}


@keyframes hoverCardSlideUpAndFade {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hoverCardSlideRightAndFade {
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hoverCardSlideDownAndFade {
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hoverCardSlideLeftAndFade {
  0% {
    opacity: 0;
    transform: translateX(2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}