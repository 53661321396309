.tray-details-container {
  padding: 16px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh + 100px);
  background-color: #f8f9fa;

  @media (min-width: 600px) {
    padding: 24px;
  }

  .tray-details-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;
    gap: 12px;

    .breadcrumbs {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }

    @media (min-width: 600px) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;
    }

    .back-button {
      width: auto !important;

      @media (min-width: 600px) {
        margin-right: 24px;
      }
    }
  }

  .tray-details-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 50vh;
    padding: 16px;
  }

  .tray-details-content {
    padding: 16px;
    background-color: white;
    border-radius: 8px;

    @media (min-width: 600px) {
      padding: 32px;
    }

    .tray-title {
      margin-bottom: 16px;
      color: #1a1a1a;
      font-weight: 600;
      word-break: break-word;
    }

    .tray-metadata {
      margin-bottom: 24px;
      padding-bottom: 16px;
      border-bottom: 1px solid #e0e0e0;

      @media (min-width: 600px) {
        margin-bottom: 32px;
        padding-bottom: 24px;
      }
    }

    .section-title {
      margin-bottom: 12px;
      color: #424242;
      font-weight: 500;

      @media (min-width: 600px) {
        margin-bottom: 16px;
      }
    }

    .tray-image-section {
      margin-bottom: 24px;

      @media (min-width: 600px) {
        margin-bottom: 32px;
      }
    }

    .tray-image-container {
      width: 100%;
      min-height: 250px;
      overflow: hidden;
      border-radius: 8px;
      background-color: #fafafa;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: 600px) {
        min-height: 400px;
      }

      .tray-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .no-image-placeholder {
        padding: 24px;
        text-align: center;

        @media (min-width: 600px) {
          padding: 48px;
        }
      }
    }

    .action-section {
      max-width: 600px;
      margin: 0 auto;

      .action-buttons {
        .MuiButton-root {
          padding: 8px 16px;
          font-weight: 500;

          @media (min-width: 600px) {
            padding: 12px 24px;
          }
        }
      }
    }
  }
}
