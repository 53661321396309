.receiving-container {
  .loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
  }

  .list {
    .list-container {
      width: 100%;
      height: calc(70vh - 16px);
      max-height: calc(70vh - 16px);
      padding-right: 15px;
      background-color: #ffffff;
      overflow: -moz-scrollbars-vertical;
      overflow-y: scroll;
    }

    .list-item {
      padding: 8px 12px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #e0e0e0;
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 9px;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--gray-8);
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: var(--gray-2);
    }
  }
}
