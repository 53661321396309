.header {
  display: flex;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.qrContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8em;
}

.toggleContainer {
  background-color: #ebf3fb;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 1.5em;
  padding: 0.3em 1em;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.loadingButtonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}

.emptyAssetList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyAssetList > * {
  color: rgba(0, 0, 0, 0.6);
}

.emptyText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.closeButton {
  width: 90%;
  margin: 1rem auto;
}

.addendumTextField {
  width: 100%;
  background-color: white;
}

.addendumsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
