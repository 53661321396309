.capture-container {
  width: 50vw;
  background: white;
  padding: 15px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.capture-container.is-tablet {
  width: 80%;
  margin-inline: 10px;
  position: relative;
  bottom: 50px;
}
