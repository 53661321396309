.process-modal {
  height: 85vh;
  width: 75vw;
  background: white;
  border-radius: 20px;
  overflow: auto;
  position: relative;

  .header {
    background: var(--light-blue-bg);
    padding: 23px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 20px 20px 0 0;

    .top {
      display: flex;
      align-items: baseline;
      gap: 10px;
    }

    .bottom {
      display: flex;
      justify-content: space-between;

      .fields {
        display: flex;
        align-items: center;
        gap: 40px;
        flex-wrap: wrap;

        .key {
          font-size: 14px;
          color: var(--lupine-blue);
        }

        p {
          font-size: 14px;
          margin: 0;
          font-weight: bold;
          display: flex;
          flex-direction: column;
        }
      }

      .switch {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;
      }
    }
  }

  .stepper {
    position: relative;
    padding-inline: 23px;

    .back-icon {
      position: absolute;
      top: 33px;
      left: 27px;
      background: var(--light-blue-bg);
      padding: 4px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 100;
    }

    .back-icon.disabled {
      background: var(--gray-1);
      color: var(--gray-2);
    }

    .MuiStepConnector-root {
      top: 18px;
    }

    .MuiStepLabel-iconContainer {
      .MuiStepIcon-root {
        font-size: 40px;
      }
      .MuiStepIcon-root {
        circle {
          color: var(--gray-4);
        }
        text {
          fill: var(--gray-3);
          font-weight: 200;
        }
      }

      .Mui-active {
        border: 2px solid var(--primary);
        border-radius: 50%;
        circle {
          color: var(--light-blue-bg);
        }

        text {
          fill: var(--primary);
          font-weight: 700;
        }
      }
    }
    .MuiStepLabel-labelContainer {
      .MuiStepLabel-label {
        font-weight: 200;
      }
      .MuiStepLabel-label.Mui-active {
        font-weight: 700;
      }
    }
  }

  .options {
    overflow: hidden;
    padding: 18px 0;

    .step-content {
      display: flex;
      justify-content: space-between;
    }

    .items {
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding-inline: 15%;
      flex-wrap: wrap;
      gap: 30px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .circle {
          padding: 60px;
          border-radius: 50%;
          background: var(--gray-1);
          position: relative;

          .icon {
            position: absolute;
            top: 0;
            right: 0px;
            background-color: var(--primary);
            padding: 5px;
            color: white;
            border-radius: 50%;
            height: 60px;
            width: 60px;
            font-weight: bold;
            stroke-width: 70px;
            transform: scale(0);

            /* Animation */
            animation: scaleUpAndDown 0.3s ease-in-out forwards;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
}

.process-modal.is-tablet {
  height: 100vh;
  border-radius: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .header {
    background: white;
  }

  .switch {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
  }

  .actions {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    background-color: white;
    padding: 20px 0;
  }

  .stepper {
    position: relative;
    padding-inline: 0;
    border-radius: 0;
    width: 100%;
    margin-bottom: 200px;
    .MuiStep-root {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .MuiStepLabel-alternativeLabel.MuiStepLabel-root.MuiStepLabel-vertical {
      width: 100vw;
      padding: 0;
      background: var(--primary);
    }
    .MuiStepLabel-alternativeLabel.MuiStepLabel-root.MuiStepLabel-vertical.active {
      width: 100vw;
      padding: 0;
      background: var(--light-blue-bg);
    }
    .MuiStepLabel-alternativeLabel.MuiStepLabel-root.MuiStepLabel-vertical.upcoming {
      width: 100vw;
      padding: 0;
      background-color: var(--gray-4);
    }
    .label-container {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      background: var(--primary);
      padding: 10px 20px 25px 20px;

      .details {
        display: flex;
        flex-direction: column;

        /* return this to start when adding the value  */
        justify-content: center;
        align-items: center;
        .label {
          font-size: large;
          color: var(--gray-9);
          font-weight: 600;
        }
        .value {
          color: white;
          font-weight: 700;
          font-size: larger;
        }
      }
      .step-number {
        height: 44px;
        width: 44px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--gray-1);
        position: relative;
        font-size: 18px;
        font-weight: 600;
        .icon {
          color: var(--primary);
          border-radius: 50%;
          height: 25px;
          width: 25px;
          font-weight: bold;
          stroke-width: 70px;
          transform: scale(0);

          /* Animation */
          animation: scaleUpAndDown 0.3s ease-in-out forwards;
        }
      }
    }
    .label-container.upcoming {
      background-color: var(--gray-4);

      .details {
        .label {
          color: var(--gray-9);
          font-size: 18px;
          font-weight: 200;
        }
        .value {
          color: var(--dark-1);
        }
      }
      .step-number {
        color: var(--gray-9);
        background: white;
        font-weight: 300;
      }
    }
    .label-container.active {
      background: var(--light-blue-bg);

      .details {
        .label {
          color: var(--primary);
          font-weight: 700;
        }
        .value {
          color: var(--dark-1);
        }
      }
      .step-number {
        color: var(--primary);
        background: white;
        font-weight: 700;
        border-radius: 50%;
        border: 2px solid var(--primary);
      }
    }

    .options {
      height: 0px;
      padding: 0px;
      opacity: 0;
      overflow: hidden;
      transition: height 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;

      .step-content {
        display: flex;
        justify-content: space-between;
      }

      .items {
        display: flex;
        justify-content: center !important;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        padding-inline: 0;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;

          .circle {
            padding: 0px;
            height: 65px;
            width: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: var(--gray-1);
            position: relative;

            .icon {
              position: absolute;
              top: 40px;
              right: -10px;
              background-color: var(--primary);
              padding: 5px;
              color: white;
              border-radius: 50%;
              height: 35px;
              width: 35px;
              font-weight: bold;
              stroke-width: 70px;
              transform: scale(0);

              /* Animation */
              animation: scaleUpAndDown 0.3s ease-in-out forwards;
            }
          }
        }
      }
    }
    .options.active.form {
      height: 100%;
      opacity: 100%;
    }
    .options.active {
      height: fit-content;
      padding: 20px 0 10px;
      width: 100%;
      opacity: 100%;
    }
    .options.active.inspection {
      height: fit-content;
      opacity: 100%;
    }
  }
}

@keyframes scaleUpAndDown {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.create-product {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 30px;
  padding: 10px 40px 25px;

  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
}
.create-product.is-tablet {
  width: 100vw;
  flex-direction: column;
  gap: 15px;
}
