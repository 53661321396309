.add-new-labor-cost-container {
  background: white;
  border-radius: 12px;
  box-shadow: 0 0 10px rgb(161, 161, 161);
  width: fit-content;
  min-width: 33%;

  &.is-upload-mode {
    min-height: fit-content;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background: var(--primary-light);
    padding: 20px;
  }

  .manual-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .input-container {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .label {
        font-size: 1rem;
        font-weight: 600;
      }

      .input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid lightgray;
        outline: none;
        transition: 0.3s;

        &:focus {
          border: 1px solid var(--primary);
        }
      }
    }
  }
  .upload-body {
    .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      gap: 20px;
      padding-top: 30px;

      .helper-text {
        text-align: center;
        span {
          font-weight: bolder;
          color: var(--primary);
        }
      }

      .upload-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 180px;
        border-top: 1px dashed var(--primary-light);

        .upload-icon {
          display: flex;
          flex-direction: column;
          height: 85px;
          width: 85px;
          border: 1px solid var(--primary-light);
        }
      }
    }

    .table-container {
      display: flex;
      flex-direction: column;
      padding: 15px 0 10px;

      .actions {
        margin-right: 15px;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
