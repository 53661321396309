.update-par-value-container {
  background: white;
  width: 80vw;
  border-radius: 12px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background: var(--primary-light);
    padding: 20px;
  }

  .body {
    padding: 20px 15px;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
  }
}
