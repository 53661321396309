.view-image-dialog_wrapper {
  .dialog-content {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: black;

    .header {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(10px);
      z-index: 10;

      .header-left {
        display: flex;
        align-items: center;
        gap: 12px;

        .title {
          color: white;
          font-size: 1.125rem;
          font-weight: 500;
        }

        .close-button {
          color: white;
        }
      }

      .header-right {
        .submit-button {
          color: var(--primary);
          font-weight: 500;
        }
      }
    }

    .main-content {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding-bottom: 96px;

      .image-container {
        flex: 1;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .image-wrapper {
          position: absolute;
          inset: 0;
          padding: 16px;

          .preview-image {
            position: absolute;
            max-width: min(100vw - 32px, 100vh - 32px);
            max-height: min(100vw - 32px, 100vh - 32px);
            width: auto;
            height: auto;
            object-fit: contain;
            transform-origin: center center;
            will-change: transform;
            transition: transform 0.3s ease-out;
            top: 50%;
            left: 50%;
            transform-origin: center center;
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
              0 0 20px rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            
            &.loading {
              opacity: 0;
            }
          }

          .skeleton-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: min(100vw - 32px, 100vh - 32px);
            height: min(100vw - 32px, 100vh - 32px);
            background: linear-gradient(90deg, #1a1a1a 25%, #2a2a2a 50%, #1a1a1a 75%);
            background-size: 200% 100%;
            animation: shimmer 2s infinite;
            border-radius: 4px;
          }

          @keyframes shimmer {
            0% {
              background-position: 200% 0;
            }
            100% {
              background-position: -200% 0;
            }
          }
        }
      }

      .bottom-controls {
        position: fixed;
        bottom: env(safe-area-inset-bottom, 0px);
        left: 0;
        right: 0;
        padding: 16px;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.95));
        display: flex;
        justify-content: center;
        gap: 24px;
        z-index: 100;
        min-height: 80px;
        touch-action: none;

        .control-button {
          color: white;
          width: 48px;
          height: 48px;

          svg {
            font-size: 24px;
          }

          &:disabled {
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
}
