.preference-card-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0 100px 0;
  gap: 20px;

  .header-container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .body-container {
    display: flex;
    padding: 20px;
    flex-direction: column;
    border-radius: 5px;

    .accordion {
      background-color: var(--primary-light);
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;

      .accordion-details {
        background: white;
        padding: 20px;
        border-radius: 0 0 5px 5px;

        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
          background-color: #f5f5f5;
          border-radius: 5px;
          margin-bottom: 20px;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }

        .item {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .label {
            font-size: 18px;
            font-weight: 600;
          }
          .value {
            font-size: 16px;
          }
        }

        .row {
          display: flex;
          width: 100%;
          padding: 0 40px;
          flex-wrap: wrap;
          gap: 20%;
        }

        .col {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      .medication {
        .value {
          margin: 0;
          padding: 5px 0 5px 15px;
          display: flex;
          flex-direction: column;
          gap: 7.5px;
        }
      }
    }
  }

  .warning {
    margin-top: 10px;
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    width: 100%;
  }
}

// fot tablet
@media (max-width: 768px) {
  .preference-card-container {
    .body-container {
      .accordion {
        .accordion-details {
          .image {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
