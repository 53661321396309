.preference-card-item {
  display: flex;
  align-items: center;
  gap: 40px;
  background: var(--primary-light);
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .details {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20%;

    .section {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
  .saving {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &.header {
    cursor: default;
    pointer-events: none;
    border: 1px solid var(--primary);
  }
}

.preference-card-view {
  .preference-card-header {
    &.scrolled {
      .preference-card-item {
        background: var(--primary);
        color: white;

        .progress {
          color: white;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .preference-card-item {
    .details {
      flex-direction: column;
      align-items: start;
      gap: 15px;
    }
  }
}
