.spd-container_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .discard-exit-button {
    position: absolute;
    top: 14px;
    left: 16px;
  }

  .config-buttons_container {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  .tray-details-button {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    .settings-icon {
      width: 25px;
      height: 25px;
    }
  }

  .spd-tray-map-page_wrapper {
    width: 100%;
    height: calc(100vh - 64px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .step-layout_header {
    position: relative;
    width: 100%;

    .stepper {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
      padding: 1.5rem;
      display: flex;
      gap: 1px;

      .MuiStepConnector-root {
        display: none;
      }

      .MuiStep-root {
        padding: 0;
        margin: 0;
        position: relative;
        flex: 1;

        &:not(:first-child) {
          margin-left: -20px;
          z-index: 1;

          &.Mui-completed {
            z-index: 2;
          }
        }

        .MuiStepLabel-root {
          background-color: var(--grayscale-light);
          padding: 0 24px 0 48px;
          width: 100%;
          min-height: 65px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 0;
          position: relative;
          clip-path: polygon(
            0 0,
            calc(100% - 20px) 0,
            100% 50%,
            calc(100% - 20px) 100%,
            0 100%,
            20px 50%
          );

          &::after {
            content: '';
            position: absolute;
            right: 20px;
            top: 0;
            height: 100%;
            width: 1px;
            background: rgba(0, 0, 0, 0.1);
            z-index: 2;
          }
        }

        &:first-child .MuiStepLabel-root {
          border-radius: 8px 0 0 8px;
          clip-path: polygon(
            0 0,
            calc(100% - 20px) 0,
            100% 50%,
            calc(100% - 20px) 100%,
            0 100%
          );
          padding: 0 24px;
        }

        &:last-child .MuiStepLabel-root {
          border-radius: 0 8px 8px 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20px 50%);

          &::after {
            display: none;
          }
        }

        .MuiStepLabel-labelContainer {
          display: flex;
          align-items: center;
        }

        .MuiStepLabel-label {
          font-size: 0.6rem;

          @media screen and (min-width: 1200px) {
            font-size: 0.875rem;
          }
        }

        &:not(:last-child) .MuiStepLabel-root::after {
          display: none;
        }

        &.Mui-completed,
        &.Mui-active {
          z-index: 2;

          .MuiStepLabel-root::after {
            background: rgba(255, 255, 255, 0.2);
          }

          .MuiStepLabel-root {
            background-color: var(--primary);
            color: var(--white);

            &::before {
              background: rgba(255, 255, 255, 0.2);
            }
          }

          .MuiStepLabel-label {
            color: var(--white) !important;
          }

          .MuiStepIcon-root {
            color: var(--white);
          }
        }
      }
    }
  }

  .stepper_content {
    width: 100%;
  }
}
