.assigned-digital-trays-container {
  width: 100%;
  padding: 24px;
  padding-bottom: 100px;
  background-color: var(--grayscale-lightest);

  .header-button {
    margin-bottom: 24px;
    color: var(--primary);
    
    &:hover {
      background-color: var(--primary-lightest);
    }
  }

  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    h2 {
      color: var(--primary-dark);
    }

    button {
      background-color: var(--primary);
      &:hover {
        background-color: var(--primary-dark);
      }
    }
  }

  .trays-content {
    margin-top: 16px;

    .tray-accordion {
      margin-bottom: 24px;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
      border: 1px solid var(--grayscale-light);
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12);
      }

      .accordion-summary {
        background-color: white;
        
        &:hover {
          background-color: var(--primary-lightest);
        }

        .summary-content {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 16px;
          gap: 24px;

          .parent-tray-image {
            width: 120px;
            height: 120px;
            object-fit: cover;
            border-radius: 12px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;

            &:hover {
              transform: scale(1.05);
            }
          }

          .tray-info {
            flex: 1;

            .tray-title {
              font-size: 1.25rem;
              font-weight: 600;
              color: var(--primary-dark);
              margin-bottom: 8px;
            }

            .tray-barcode {
              color: var(--grayscale);
              font-size: 0.875rem;
            }
          }

          .tray-count {
            background: var(--primary);
            color: white;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            box-shadow: 0 2px 8px rgba(80, 53, 237, 0.3);
            margin-right: 48px;
          }
        }
      }

      .accordion-details {
        background: linear-gradient(to bottom, white, var(--grayscale-lightest));
        padding: 24px;

        .sub-trays-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
          gap: 24px;
          padding: 8px;
        }
      }
    }
  }
}
