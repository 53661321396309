.loaner-tray-analysis-content_wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--grayscale-lightest);
  border-radius: 12px;

  .header-section {
    background: white;
    border-bottom: 1px solid var(--grayscale-light);
    padding: 16px 20px;
    width: 100%;
    margin-top: 8px;

    .header-content {
      max-width: 1400px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .main-title {
        color: var(--primary);
        font-weight: 600;
        font-size: 28px;
      }

      .header-actions {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .tray-info {
          color: var(--grayscale-dark);
          font-size: 16px;

          strong {
            color: var(--primary);
            margin-left: 4px;
          }
        }

        .help-button {
          display: flex;
          align-items: center;
          gap: 8px;
          height: 40px;
          padding: 0 24px;
          border-radius: 20px;
          border-color: var(--primary-light);
          color: var(--primary);
          font-weight: 500;
          transition: all 0.2s ease;

          &:hover {
            background-color: var(--primary-lightest);
            transform: translateY(-1px);
          }

          svg {
            font-size: 18px;
          }
        }
      }
    }
  }

  .legend-banner {
    background-color: var(--primary-lightest);
    border: 1px solid var(--primary-light);
    margin: 16px 40px;
    border-radius: 8px;
    padding: 12px 24px;
    margin-bottom: 16px;

    .legend-content {
      display: flex;
      gap: 24px;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .legend-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 8px;
        border-radius: 4px;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.6);
        }

        .count-circle {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          background-color: #f5f5f5;
          border: 2px solid transparent;

          &:first-child {
            background-color: #f5f5f5;
            border-color: #ddd;
          }

          &:nth-child(2) {
            background-color: #add8e6;
            border-color: transparent;
          }

          &:last-child {
            background-color: #add8e6;
            border-color: #1976d2;
          }
        }
      }
    }
  }

  .info-banner {
    max-width: 1400px;
    margin: 24px auto;
    padding: 16px 24px;
    background-color: var(--primary-lightest);
    border: 2px solid var(--primary-light);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--primary);

    .icon {
      font-size: 24px;
    }

    strong {
      font-weight: 600;
    }
  }

  .content-section {
    flex: 1;
    padding: 0 40px 24px;

    .analysis-panels {
      max-width: 1400px;
      margin: 0 auto;
      display: flex;
      gap: 24px;
      height: 100%;
      min-height: 600px;

      .panel {
        background: white;
        border-radius: 12px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &.image-panel {
          flex: 0 0 40%;
        }

        &.map-panel {
          flex: 0 0 60%;
        }

        .panel-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 18px 24px;
          border-bottom: 1px solid var(--grayscale-light);
          background-color: white;

          &.tray-map-header {
            padding: 12px 24px;
          }

          h6 {
            color: var(--primary);
            font-weight: 600;
            font-size: 18px;
          }

          .load-all-container {
            display: flex;
            justify-content: center;
            gap: 16px;
          }
        }

        .panel-content {
          flex: 1;
          padding: 24px;
          background-color: var(--grayscale-lightest);
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 0;

          .tray-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 8px;
            transition: transform 0.3s ease;
            cursor: zoom-in;

            &:hover {
              transform: scale(1.02);
            }
          }
        }

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .footer-section {
    position: sticky;
    bottom: 0;
    padding: 16px 40px;
    background: white;
    border-top: 1px solid var(--grayscale-light);
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    z-index: 10;
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.06);

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      height: 20px;
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0.9),
        transparent
      );
      pointer-events: none;
    }

    .cancel-button {
      flex: 1;
    }

    .confirm-button {
      flex: 1;
      height: 48px;
      padding: 0 32px;
      border-radius: 24px;
      font-size: 16px;
      font-weight: 500;
      text-transform: none;
      transition: all 0.2s ease;

      animation: subtle-bounce 2s infinite;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(90, 67, 233, 0.2);
        animation: none;
      }
    }
  }
}

@keyframes subtle-bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}

.image-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
    background: white;
    border-radius: 12px;
    padding: 24px;
    outline: none;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);

    .close-button {
      position: absolute;
      top: 16px;
      right: 16px;
      background: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.2s ease;

      &:hover {
        background: var(--grayscale-lightest);
        transform: rotate(90deg);
      }
    }

    .full-size-image {
      max-width: 100%;
      max-height: calc(90vh - 48px);
      object-fit: contain;
      border-radius: 8px;
    }
  }
}
