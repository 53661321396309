.camera-capture {
  .video-canvas {
    width: 100%;
    border-radius: 4px;
  }

  .camera-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .camera-icon {
      height: 60px;
      width: 60px;
      cursor: pointer;
      border: 1px dashed var(--primary-light);
      padding: 5px;
      margin: 10px 0;
      border-radius: 4px;

      &.disabled {
        color: var(--grayscale);
        cursor: not-allowed;
      }
    }
  }
}
