.capture-tray {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 32px;
  padding: 24px;
  background: #f8f9fa;

  .close_button {
    position: absolute;
    top: -40px;
    right: -60px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    // border-radius: 50%;
  }

  .product-container {
    .product-info {
      padding: 16px;
      background: white;
      border-radius: 12px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      margin-bottom: 24px;

      .description {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .company,
      .count {
        color: #6c757d;
        margin-bottom: 8px;
      }
    }

    .manual-input_container {
      background: white;
      padding: 16px;
      border-radius: 12px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

      .section-title {
        margin-bottom: 16px;
        font-weight: 500;
      }

      .manual-image_container {
        margin-top: 16px;
        position: relative;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }
    }
  }

  .camera-section {
    position: relative;

    .camera-container {
      background: white;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      position: relative;

      .camera-controls {
        position: absolute;
        top: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 20;
        padding: 0 16px;

        .capture-and-preview {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 16px;

          .preview-container {
            position: relative;
            width: 180px;
            height: 120px;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            z-index: 10;
            border: 2px solid white;
            background: #000;

            .captured-img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              background: #000;
            }

            .preview-label {
              position: absolute;
              top: 8px;
              left: 8px;
              background: rgba(0, 0, 0, 0.75);
              color: white;
              padding: 4px 8px;
              border-radius: 4px;
              font-size: 12px;
              font-weight: 500;
              backdrop-filter: blur(4px);
            }
          }
        }

        .camera-select_container {
          .camera-switch {
            background: rgba(0, 0, 0, 0.75);
            backdrop-filter: blur(8px);
            border-radius: 50px;
            padding: 8px 16px;
            color: white;
            border: 1px solid rgba(255, 255, 255, 0.2);
            transition: all 0.2s ease-in-out;

            &:hover {
              background: rgba(0, 0, 0, 0.9);
              transform: translateY(-1px);
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }

            &:active {
              transform: translateY(0);
            }

            .MuiButton-startIcon {
              margin-right: 8px;
            }
          }
        }

        .capture-button {
          background: var(--primary);
          border-radius: 50px;
          padding: 10px 24px;
          font-weight: 600;
          letter-spacing: 0.3px;
          transition: all 0.2s ease-in-out;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          width: auto;

          &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
          }

          &:active {
            transform: translateY(0);
          }

          .icon {
            margin-right: 8px;
            font-size: 20px;
          }

          &.retake {
            background: #dc3545;

            &:hover {
              background: #c82333;
            }
          }
        }
      }

      .feed {
        width: 100%;
        height: 500px;
        object-fit: cover;
      }

      .camera-options {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        background: white;
        border-radius: 12px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        min-width: 250px;
        overflow: hidden;

        .camera-option {
          padding: 12px 16px;
          cursor: pointer;
          transition: all 0.2s;
          display: flex;
          flex-direction: column;
          gap: 4px;
          border-bottom: 1px solid #eee;

          &:last-child {
            border-bottom: none;
          }

          .label {
            font-weight: 500;
            color: #2c3e50;
          }

          .type {
            font-size: 12px;
            color: #6c757d;
          }

          &:hover {
            background: #f8f9fa;
          }

          &.active {
            background: var(--primary);

            .label,
            .type {
              color: white;
            }
          }
        }
      }
    }
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--grayscale);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 30;
  }

  .action-container {
    margin-top: 24px;
    display: flex;
    justify-content: center;

    .submit-button {
      min-width: 200px;
      height: 48px;
    }

    .instructions {
      text-align: center;
      color: #6c757d;

      .instruction-title {
        margin-bottom: 16px;
        color: #212529;
      }
    }
  }
}

@keyframes moveBottomLeft {
  0% {
    bottom: 50%;
    right: 50%;
    transform: translate(-50%, 50%);
  }
  100% {
    bottom: 30px;
    right: 100%;
    transform: translate(calc(100% + 60px), 0%);
  }
}

@keyframes showSubmitAfterMove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
