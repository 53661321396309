.labor-cost-main-container {
  .labor-cost-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0 0 5px;

    .search {
      margin: 0 15px 10px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
