.assign-step {
  max-width: 800px;
  margin: 0 auto;

  .tray-case-details-organism_container {
    padding: 0;

    & > h5:first-child {
      display: none;
    }

    .filters_container {
      padding: 1.5rem;
      background-color: #fff;
      border-radius: 0.75rem;
      border: 1px solid #e0e0e0;
      box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.05);

      .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
      }
    }
  }
}
