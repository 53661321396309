.approve-reject-tray-molecule_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 500px;
  max-height: 95%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 1em;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto;
  z-index: 500;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-direction: row;
    color: var(--primary-contrast-text);
  }

  .confirmation-buttons_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}
