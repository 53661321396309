.scanned-item {
  background: var(--gray-4);
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 12px;

  .top {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 10px;

    .right {
      display: flex;
      align-items: center;
      gap: 5px;

      .flag {
        background: var(--light-blue);
        color: var(--primary);
        font-weight: bold;
        font-size: 14px;
        padding: 0 5px;
        border-radius: 5px;
      }
      .quantity {
        background: var(--light-blue-2);
        color: white;
        font-weight: bold;
        font-size: 14px;
        padding: 0 5px;
        border-radius: 5px;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    justify-content: space-between;

    .details {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      .col {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
      }

      p {
        font-size: 11px;
        margin: 0;
        font-weight: bold;
      }

      .key {
        font-size: 11px;
        color: var(--lupine-blue);
      }
    }
  }
}
.scanned-item.is-tablet {
  background: var(--light-blue-bg);
  border-radius: 0;
}

.scanned-item.highlight {
  padding: 20px;
  border: 2px solid var(--primary);
  animation: pulse 4s infinite;
}

.scanned-item.error {
  padding: 20px;
  border: 2px solid var(--red);
}

@keyframes pulse {
  0%,
  100% {
    border-color: var(--primary);
  }
  50% {
    border-color: transparent;
  }
}
