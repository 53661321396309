.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.deleteConfirmation {
  align-self: center;
  color: #1d4622;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.loadingButtonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}
