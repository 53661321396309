.load-multiple-screws_box {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 70vw;
  max-height: 30%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto;
  z-index: 50;
  top: 55px;
  transform: translateY(55px);

  .confirmation_buttons-container {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}

.load-multiple-screws_box.open {
  display: flex;
}
