.dtm-tray-visualization_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 90px;
  overflow-x: hidden;
  position: relative;

  .header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;

    .guide-and-reference-image-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }
  }

  .help-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
    background-color: #5a43e9;
    border-radius: 50%;
    font-size: 13;

    opacity: 1;
    z-index: 5;
    width: 25px;
    height: 25px;
  }

  .tray-map_wrapper {
    width: 170%;
    height: 300px;
    scale: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  .screw-areas-wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: var(--tray-background);

    .screw-area {
      width: 33.3%;
      height: 50%;
      background-color: var(--tray-background);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .area-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;

        .column-size_wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin: 1px;
          padding: 1;

          .column-size {
            width: 6px;
            height: 6px;
            background-color: black;
            margin: 0.5px;
            padding: 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }

        .screw-rows_wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin: 1px;
          padding: 0;
        }
      }
    }
  }

  .buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    button {
      width: 100%;
    }

    .left-container {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .view-modify-tray_button {
        white-space: wrap;
      }
    }

    .right-container {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .floating-image-button {
    position: fixed;
    top: 90px;
    right: 24px;
    z-index: 1000;
    
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 
                0 6px 10px 0px rgba(0,0,0,0.14), 
                0 1px 18px 0px rgba(0,0,0,0.12);
  }
}

.dtm-tray-visualization_container.with-plates {
  .tray-content_wrapper {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    height: auto;
  }

  .tray-content_wrapper.view-mode {
    pointer-events: none;
  }

  .screw-areas-wrapper {
    width: 45%;

    .screw-area {
      width: 45%;
    }

    .screw-area.emergency {
      width: 20%;
    }
  }
}

@keyframes neon {
  0% {
    border-color: #36fff8;
    box-shadow: 0 0 10px #36fff8, 0 0 20px #36fff8, 0 0 30px #36fff8,
      0 0 40px #36fff8, 0 0 70px #36fff8, 0 0 80px #36fff8, 0 0 100px #36fff8,
      0 0 150px #36fff8;
  }
  100% {
    border-color: transparent;
    box-shadow: none;
  }
}

.tray-image-preview-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;

  .preview-header {
    padding: 16px 24px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .preview-controls {
      display: flex;
      align-items: center;
      gap: 8px;

      .zoom-controls {
        display: flex;
        gap: 4px;
        margin-right: 16px;
        border-right: 1px solid #e0e0e0;
        padding-right: 16px;
      }
    }
  }

  .preview-content {
    flex: 1;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    
    .react-transform-wrapper {
      width: 100%;
      height: 100%;
    }

    .tray-preview-image {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .image-loading,
    .image-error {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }
}

.close-preview-button {
  color: rgba(0, 0, 0, 0.87) !important;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}
