.location-item {
  cursor: pointer;
  padding-left: 32px;
  padding: 5px 0 5px 20px;
  margin-bottom: 1px;
  border-radius: 4px;
  position: relative;
  border: 0.5px solid var(--gray-3);
}

.location-item.is-parent {
  padding: 12px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 16px;
  border: 0.5px solid var(--gray-3);
}

.location-item:not(.is-parent):hover {
  transition: all 0.2s ease;
  border-right: 3px solid var(--primary);
  background: var(--grayscale-hover);
}

.location-item:not(.is-parent):hover::after {
  content: 'Start receiving';
  color: var(--gray-6);
  position: absolute;
  right: 20px;
  top: 30%;
  font-weight: 500;
}
