.zimmer-closure-tops-tray-map_organism {
  width: 100%;
  max-width: 420px;
  aspect-ratio: 1/1.5;
  height: auto;
  background-color: var(--zimmer-rod-background);
  border-radius: 20px;
  padding: 20px 30px 40px;
  border-top: 15px solid var(--grayscale);
  border-bottom: 15px solid var(--grayscale);
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .screws_container {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.4);
    padding: 20px 10px;
    border-radius: 20px;

    .column {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .screw_container {
        width: 100%;
        position: relative;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .ellipses_container {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.4);
    padding: 20px 10px;
    border-radius: 20px;

    .column {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .ellipse {
        width: 20px;
        height: 20px;
        background-color: black;
        border-radius: 50%;
      }
    }
  }

  .tray_title {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--grayscale-light);
    padding: 1px 5px;
    border-radius: 20px;
  }
}
