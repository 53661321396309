.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: left;
}

.nameLogo {
  margin: 0.7em;
}

.logo {
  height: 24px;
}

.offlineIndicator {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
}
