.dtm-tray-products_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 100px;

  .header-buttons_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .screw-data_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .empty-data_text {
      width: 100%;
      text-align: center;
      margin: 20px 0;
    }

    .usage-plate_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-radius: 5px;
      border: 1px solid var(--grayscale-light);
      margin-bottom: 10px;

      .qr-code {
        font-size: 35px;
      }

      .content {
        width: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
      }
    }
  }
  .confirm-button {
    margin-top: 20px;
  }
}
