.toolbar {
    display: flex;
    height: 60px;
    padding: 0 30px;
  
    .right-container {
      display: flex !important;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 30px;
    }
    .right-container.MuiBox-root {
      display: flex;
      align-items: center;
    }
  }