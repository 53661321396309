.pref-card-rep-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 5px;
    background: var(--primary-light);
  }
  .actions {
    display: flex;
    align-items: self-start;
    gap: 5px;

    .notification-checkbox {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &.is-email-sent {
    .details-container {
      background: var(--success-light);
    }
  }
  &.is-text-sent {
    .details-container {
      background: var(--success-light);
    }
  }
}
