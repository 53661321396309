.non-udi-form-container {
  padding-bottom: 50px;
  .main-container {
    margin-bottom: 16px;
    display: flex;

    .main-text-fields {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .mt-1 {
        margin-top: 7.5px;
      }

      .search-inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .optional-fields-container {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .counter-container {
      margin-top: 14px;
    }

    .save-button {
      margin-top: 30px;
    }

    .has-serial-lot-condition {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .checkbox-container {
        display: flex;
        align-items: center;
        gap: 10px;

        .checkbox {
          margin: 0;
          padding: 0;
          color: var(--primary);
          background-color: var(--light-primary);
        }
      }
    }

    .field {
      display: flex;
      align-items: center;
      gap: 5px;

      .barcode-button {
        margin-top: 10px;
      }
    }
  }

  .mt-10 {
    margin-top: 10px;
    width: 100%;
  }
}

.manual-add-main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .result {
    overflow-y: auto;
    height: 60vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 30px;

    .item {
      cursor: pointer;
      padding: 15px 20px;
      border: 1px solid var(--secondary);
      border-radius: 6px;

      &:hover {
        transition: all 0.2s ease;
        box-shadow: 0px 4px 10px rgba(10, 10, 10, 0.1);
      }
    }

    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &.is-tablet {
      height: 75vh;
    }
  }
}
