.root {
  min-height: 100vh;
}

.typography {
  color: white;
  text-align: center;
}

.logo {
  height: 3.5rem;
  margin-top: 5rem;
}
