.manual-add-container {
  padding: 12px 24px 0 24px;
  margin-bottom: 56px;
  .manual-add-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .back-icon {
      height: 35px;
      width: 35px;
      border-radius: 50%;

      &:hover {
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        background: var(--primary-light);
      }
    }

    .actions {
      display: flex;
      column-gap: 1.25rem;
    }
  }
}
