.textinput-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    
    .textinput-form {      
      &.fullwidth {
        width: 100%;
      }
  
      &.outlined {
        background-color: white;
      }
  
      &.standard {
        background-color: transparent;
      }
    }
  
    .input-caption {
      color: var(--error);
      margin-left: 0.5em;
    }
  }
  