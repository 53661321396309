.spd-scan {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .mode-banner {
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;

    &.scan-mode {
      background-color: var(--primary-lightest);
      border: 2px solid var(--primary-light);
      color: var(--primary);
    }
  }

  .content-container {
    display: flex;
    gap: 32px;
    align-items: stretch;
    min-height: 600px;

    .section-title {
      margin-bottom: 16px;
      color: var(--grayscale-dark);
      font-weight: 600;
    }

    .reference-section {
      flex: 1;
      display: flex;
      flex-direction: column;

      .tray-image {
        flex: 1;
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid var(--grayscale-light);
        background-color: var(--grayscale-lightest);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: inherit;
        }
      }
    }

    .scanner-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .scanner {
        flex: 1;
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid var(--grayscale-light);
        background-color: var(--grayscale-lightest);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

        .skeleton {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 13;
        }

        .scanned-code_wrapper {
          position: absolute;
          top: 16px;
          right: 16px;
          padding: 8px 16px;
          background-color: var(--primary);
          border-radius: 8px;
          color: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

          .scanned-text_wrapper {
            display: flex;
            align-items: center;
            gap: 8px;

            .spinner {
              color: white;
            }

            .scanned-text {
              font-size: 14px;
              font-weight: 500;
              margin: 0;
            }
          }

          .scanned-code {
            font-size: 12px;
            margin: 4px 0 0;
            opacity: 0.8;
          }
        }
      }

      .input-section {
        padding: 16px;
        background-color: white;
        border-radius: 12px;
        border: 1px solid var(--grayscale-light);

        .manual-input {
          margin-top: 8px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .spd-scan {
    .content-container {
      flex-direction: column;
    }
  }
}

@keyframes scan {
  0% {
    right: 20px;
  }
  100% {
    right: calc(100% - 20px);
  }
}

@keyframes moveTopRight {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 10px;
    left: calc(100% - 10px);
    transform: translate(calc(-100% - 10px), 0);
  }
}
