.button-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
}

.condensed-button-box {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 1em;
    padding: 10px 30px;

    .condensed-button {
        max-width: fit-content;
    }
}