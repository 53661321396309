.new-referring-physician-main-container {
  .content {
    display: flex;
    padding: 20px 20px 20px 25px;
    .input-container {
      flex: 1;
    }
    .submit-button-container {
      flex: 1;
      text-align: right;
      .submit-button {
        margin-top: 10px;
        width: fit-content;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .new-referring-physician-main-container {
    .content {
      flex-direction: column;
      .submit-button-container {
        .submit-button {
          width: 100%;
        }
      }
    }
  }
}
