.table-container {
  border: 1px solid var(--gray-10);
  border-radius: 4px;
  display: flex;

  .table-head {
    background-color: var(--gray-10);

    .table-cell-head {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: left;
    }
  }

  .table-row-initial-state {
    display: flex;
    justify-content: center;
    width: 120%;

    .table-cell-loading {
      transform: translateX(55%);
    }

    .table-cell-empty {
      text-align: center;
    }
  }

  .table-row {
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-10);
    text-decoration: none;

    &:hover {
      background-color: var(--gray-1);
    }

    .table-cell-event-status {
      text-align: center;
      padding: 1rem;
      width: 40%;
      border-bottom: none;
      position: relative;

      .bell-icon {
        position: absolute;
        position: absolute;
        top: 0;
        right: 0;
        margin: 10px;
        color: var(--primary);
      }
    }

    .table-cell-case-info {
      width: 100%;
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      gap: 2rem;
      padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-right: 0;
      border-bottom: none;

      .typography {
        margin-top: 1px;
        width: 1px;
        padding: 2px;
      }

      .room {
        flex-direction: column;
        display: flex;
        background-color: var(--primary);
        color: var(--white);
        border-radius: 4px;
        align-items: center;
        padding: 0.5rem;
        justify-content: center;
        gap: 0.5rem;
      }

      .patient {
        flex-direction: column;
        display: flex;
        width: 70%;
      }
    }

    .table-cell-case-info.is-mobile {
      gap: 1.5rem;
    }
  }
}
