.filters-menu-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 1px;
    border-radius: 4px;
    padding: 1px;

    .filters {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0.5em;
        margin-top: 1em;;
    }

    .input-container {
        width: 200px;
    }
}

.input-container.is-tablet {
        width: 90%;
}