.report-details-modal-container {
  background: white;
  width: 90vw;
  border-radius: 12px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background: var(--primary-light);
    padding: 20px;
  }

  .loader {
    width: 100%;
    height: 100%;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
