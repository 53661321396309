.scanner-actions {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 100px;

  margin-top: 20px;

  &.is-mobile {
    top: 90px;
  }

  .carousel-container {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: auto;
    display: flex;
    align-items: center;

    .asset-type-container {
      color: white;
      font-weight: 700;
      background: black;
      font-size: 14px;
      opacity: 75%;
      padding: 6px;
      border-radius: 6px;
      max-width: 300px;
      word-wrap: break-word;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .arrow-button {
      opacity: 1;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}

.react-swipeable-view-container {
  align-items: center;
}
