.top-header-container {
  min-height: 57px !important;
  display: flex;
  padding: 12px 30px 9px;
  background-color: var(--light-blue-bg);
  align-items: center;
  gap: 15px;
  justify-content: space-between;

  .left-container {
    display: flex;
    align-items: center;
    gap: 15px;

    .header-title {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &.is-mobile {
    padding: 12px 15px 9px;

    .left-container {
      gap: 5px;
    }

    .title {
      font-size: 12px;
    }
  }
}
