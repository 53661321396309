.confirm-load-screw_modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 500px;
  max-height: 95%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 1em;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto;

  .header-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    text-transform: capitalize;
  }

  .scanner-container {
    width: 100%;
    height: 260px;
    border-radius: 25px;
    position: relative;
    margin: 20px 0;

    .skeleton {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 13;
    }
  }

  .buttons_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1em;
    margin-top: 25px;
  }

  .scanned-code_wrapper {
    margin: 15px 0;
    padding: 5px 20px;
    background-color: #5035ed;
    border-radius: 15px;
    color: white;

    .scanned-text_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .spinner {
        color: white;
        margin-right: 5px;
      }

      .scanned-text {
        font-size: 11px;
        font-weight: 500;
      }
    }

    .scanned-code {
      font-size: 8px;
      font-weight: 400;
    }
  }

  .error {
    color: var(--error);
    text-align: center;
  }

  .product-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;

    .description {
      margin-bottom: 10px;
    }

    .details {
      margin-bottom: 5px;
    }
  }
}
