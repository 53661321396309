.tray-files-page_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 80px 12px 0;

  .files_container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;

    .header_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
    }

    .files_wrapper {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 20px;

      .inventory-sheet_file {
        width: calc(33.33% - 20px);
        height: 200px;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid var(--grayscale-dark);

        img,
        a,
        .pdf-viewer {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          text-decoration: none;
          color: var(--primary);
          font-weight: bold;
        }
      }
    }
  }
}
