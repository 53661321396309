.edit-tray-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 500px;
  max-height: 95%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 1em;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto;
  z-index: 3000;

  .header-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    text-transform: capitalize;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

@media screen and (width < 600px) {
  .edit-tray-modal {
    width: 95%;
  }
}
