.tray-plates-area-organism_container {
  width: 100%;
  max-width: 55%;
  height: 100%;
  background-color: var(--grayscale-contrast-text);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;

  .plate_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px;
    border-radius: 50px;
    width: auto;
    background-color: var(--grayscale-dark);
    cursor: pointer !important;
    position: relative;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }

  .plate-row_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .plate-column_wrapper {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
}

@media (max-width: 768px), screen and (orientation: landscape) {
  .tray-plates-area-organism_container.is-mobile {
    gap: 5px;
    padding: 5px;

    .plate_container {
      max-width: 50px;
      max-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 5px;
      border-radius: 50px;
      background-color: var(--grayscale-dark);
      cursor: pointer !important;
      position: relative;

      img {
        max-height: 45px;
        max-width: 100%;
        object-fit: contain;
      }
    }

    .plate-row_wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
      gap: 5px;
    }

    .plate-column_wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
}
