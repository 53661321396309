.edit-count-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: var(--primary-light);
  cursor: pointer;
  transition: width 0.3s ease, opacity 0.3s ease;
  color: var(--primary-contrast-text);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.85;

  &:hover {
    width: 100px;
    opacity: 1;
  }

  .count {
    font-size: 14px;
  }

  .decrease-button,
  .increase-button {
    color: var(--primary-contrast-text);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 30px;
    border: none;
    background-color: transparent;
    font-size: 18px;
    cursor: pointer;
    transition: width 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    min-width: 0px;
    min-height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .decrease-button:disabled,
  .increase-button:disabled {
    color: var(--primary-disabled);
  }

  &:hover .decrease-button,
  &:hover .increase-button {
    width: 25px;
    opacity: 1;
  }

  .decrease-button {
    left: 10px;
  }

  .increase-button {
    right: 10px;
  }
}

@media screen and (max-width: 800px), screen and (orientation: landscape) {
  .edit-count-container.is-mobile {
    width: 12px;
    height: 12px;

    .count {
      font-size: 4px;
    }

    .decrease-button,
    .increase-button {
      font-size: 4px;
      height: 9px;
      width: 9px;
      padding: 0;
    }

    &:hover {
      width: 30px;
      opacity: 1;
    }

    &:hover .decrease-button,
    &:hover .increase-button {
      width: 9px;
      opacity: 1;
    }

    .decrease-button {
      left: 1px;
    }

    .increase-button {
      right: 1px;
    }
  }
}
