.product-email-main-container {
  padding-bottom: 5px;
  &:not(:last-of-type) {
    border-bottom: 1px dashed var(--grayscale);
  }

  .product-email-container {
    .header {
      padding: 10px 10px 5px;
      display: flex;
      align-items: start;
      padding-right: 10px;
      justify-content: space-between;
    }

    .email-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 0px;

      .email {
        margin: 0;
        margin-left: 10px;
        width: 50%;

        .email-block-container {
          display: flex;
          align-items: center;
          gap: 5px;

          .email-block {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            border-radius: 5px;
            background: var(--primary-light);
            gap: 5px;
          }

          .email-actions {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }

        .email-input {
          padding: 5px 10px;
        }
      }

      .actions-container {
        display: flex;
        align-items: center;
        gap: 15px;
      }

      &.is-editing {
        .email {
          padding: 0;
          margin-left: 0;
        }
      }
      &.is-email-sent {
        .email {
          .email-block-container {
            .email-block {
              justify-content: space-between;
              background: var(--success-light);
              display: flex;
            }
            .email-actions {
              gap: 5px;
            }
          }
        }
      }
    }
  }
  .new-email-container {
    margin: 10px 10px 10px;

    .new-email {
      display: flex;
      flex-direction: column;

      .header {
        margin-bottom: 10px;
      }
    }

    .actions {
      display: flex;
      gap: 10px;
    }
  }

  &.is-mobile {
    .product-email-container {
      .header {
        font-size: smaller;
        margin-bottom: 10px;
      }
      .email-container {
        padding: 0 12px 0 0px;

        .email {
          width: 100%;
          overflow: hidden;
          p {
            font-size: small;
          }
        }
        .actions-container {
          gap: 5px;
          margin-left: 10px;
        }

        &.is-editing {
          display: flex;
          flex-direction: column;
          align-items: start;
        }
      }
    }
    .new-email-container {
      .actions {
        flex-wrap: wrap;
      }
    }
  }
}
