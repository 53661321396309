.print-wrapper {
  display: none;

  &.visible {
    display: block;
  }

  .print-button {
    margin-bottom: 20px;
    padding-inline: 40px;
  }

  .print-content {
    display: none;

    @media print {
      display: block;
    }
  }

  &.is-mobile {
    button {
      padding-inline: 15px;
    }
  }

  .print-section {
    padding: 16px 8px;

    @media print {
      page-break-after: always;

      &:last-child {
        page-break-after: avoid;
      }
    }
  }
}

@media print {
  @page {
    size: auto;
    margin: 20mm;
  }
}
