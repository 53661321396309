.preference-card-view {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .preference-card-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 20px;
    top: 100px;
    z-index: 100;
    position: sticky;

    &.scrolled {
      transition: all 0.3s ease;
    }

    .create {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .inputs {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        max-width: 550px;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 20px 40px;

    .mt-1 {
      margin-top: 5px;
    }
    .mt-2 {
      margin-top: 10px;
    }
    .accordion {
      background: var(--primary-light);

      .accordion-details {
        background: white;

        .details-container {
          display: flex;
          padding: 10px;
          width: 100%;
          gap: 40px;

          .section {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;

            &.center {
              margin: 0;
              align-items: center;
              justify-content: start;
            }

            .checkbox-group {
              display: flex;
              gap: 10px;
              flex-wrap: wrap;
            }
          }

          .row {
            width: 100%;
            display: flex;
            gap: 20px;
            align-items: start;
            justify-content: space-between;

            &.item {
              align-items: center;
              justify-content: start;
            }

            &.center {
              align-items: center;
            }

            &.start {
              justify-content: start;
            }

            &.full-width {
              width: 100%;
            }

            .width-1 {
              min-width: 300px;
            }

            .text-box {
              display: flex;
              align-items: center;
              gap: 10px;
              width: 170px;
              min-width: 130px;
            }
          }

          .flex {
            display: flex;
            gap: 20px;
          }

          .notes-row {
            display: flex;
            gap: 20px;
            align-items: start;
            justify-content: start;
            flex-wrap: wrap;

            .notes-container {
              padding: 10px;

              .section {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
              }
            }
          }

          .col {
            flex-direction: column;
            gap: 2px;
            width: 100%;

            &.equipments {
              gap: 5px;
            }
          }

          &.half-width {
            width: 70%;
          }
        }

        .row {
          display: flex;
          align-items: center;
          gap: 5px;

          .add-button {
            margin-bottom: 16px;
            padding: 7px 10px;
            min-width: 120px;
          }
        }

        .col {
          flex-direction: column;
          gap: 2px;
          width: 100%;

          &.equipments {
            gap: 5px;
          }
        }

        .wrap {
          flex-wrap: wrap;
        }

        .products-container {
          .products-col {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 60%;

            .product-row {
              display: flex;
              gap: 10px;
              align-items: center;
              .left {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid var(--grayscale-light);
              }
            }
          }
        }

        .table-rotation-options {
          display: flex;
          align-items: self-start;
          gap: 10px;
        }
      }

      .implant-products-container {
        margin-top: 10px;
        border: 1px solid var(--grayscale-light);
        padding: 10px 15px;
        border-radius: 5px;

        &.disabled {
          background-color: var(--grayscale-lightest);
          color: var(--grayscale);
        }
      }
      .selected-products {
        display: flex;
        flex-direction: column;
        gap: 15px; // Increase the gap to separate company groups more clearly
        margin-bottom: 10px;

        .company-group {
          display: flex;
          flex-direction: column;
          gap: 7.5px;
          padding: 5px 10px;
          border-radius: 5px;
          background: var(--grayscale-lightest);

          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 15px;
          }

          h4 {
            font-size: 14px;
            font-weight: bold;
            color: var(--primary);
            margin-bottom: 5px;
          }
        }
      }
      .product {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px dashed var(--grayscale-light);
        border-radius: 5px;
        padding: 5px 15px;
        background: var(--grayscale-lightest);

        .actions {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .invite-switch {
          display: flex;
          align-items: center;
        }
      }

      .new-rep-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .notify-rep {
          .rep-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }
      }
    }

    .positions-container {
      .positions {
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 10px;

        .position {
          position: relative;
          overflow: hidden;
          border: 2px solid transparent;
          transition: border-color 0.3s ease;

          &:hover {
            cursor: pointer;
          }

          &.selected {
            border-color: blue; /* Border color for selected position */
          }

          .position-image {
            width: 60px;
            height: 60px;
            object-fit: cover;
          }
        }
      }
    }
  }

  &.is-mobile {
    .products-container {
      .products-col {
        .product-row {
          align-items: flex-start;
          .left {
            flex-wrap: wrap;
          }
        }
      }
    }

    .flex {
      flex-wrap: wrap;
    }
  }
}

// if tablet
@media (max-width: 1024px) {
  .preference-card-view {
    .body {
      .accordion {
        .accordion-details {
          .details-container {
            flex-wrap: wrap;

            &.half-width {
              width: 100%;
            }

            .notes-row {
              display: inline;
            }

            .row {
              flex-wrap: wrap;

              .width-1 {
                min-width: 100%;
              }
            }
          }
          .products-container {
            .products-col {
              width: 100%;
            }
          }

          .table-rotation-options {
            flex-direction: column;
            gap: 10px;
          }
        }
      }
    }
  }
}
