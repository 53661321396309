.pref-cards-main-container {
  display: flex;
  flex-direction: column;

  .pref-cards {
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .surgeries {
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline: 20px;
    gap: 10px;

    .search {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      max-width: 500px;
      width: 100%;
      gap: 10px;

      .input {
        max-width: 300px;
        width: 100%;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .pref-cards-main-container {
    .filters {
      flex-wrap: wrap;

      .search {
        flex-wrap: wrap;
      }
    }
  }
}
