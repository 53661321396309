.grayscale-tray-map {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;

  .annotation-box {
    position: absolute;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center;

    .annotation-controls {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  .count-circle {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid transparent;
    background-color: #f5f5f5;
    transform-origin: center;
    min-width: 16px;
    min-height: 16px;

    &.active {
      background-color: #add8e6;
    }

    &.disabled {
      opacity: 0.6;
      background-color: #d3d3d3;
      border: 2px solid #a9a9a9;

      &:hover {
        cursor: not-allowed;
      }

      &.active {
        background-color: #b0c4de;
      }
    }
  }

  .button-container {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .purple-button {
    min-width: 20px;
    min-height: 20px;
    padding: 0;
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  // Image styles
  .gray-scale-tray-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    filter: grayscale(100%) brightness(60%);
  }

  // Annotation box styles
  .annotation-box {
    position: absolute;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.screw {
      &.selected {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid var(--primary);
          border-radius: 50%;
          background-color: rgba(90, 67, 233, 0.1);
          animation: pulse 1.5s infinite;
        }
      }

      &.disabled {
        .disabled-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 16px;
          z-index: 2;
          filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.8));
        }

        .count-circle {
          background-color: rgba(211, 211, 211, 0.8);
          border: 2px solid #a9a9a9;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
          }

          &:hover {
            cursor: not-allowed;
          }

          &.active {
            background-color: rgba(176, 196, 222, 0.8);
          }
        }
      }
    }

    // Scale based on container width
    &.screw,
    &.plate,
    &.drill {
      .annotation-controls {
        transform-origin: center;
        transform: scale(calc(100% / max(1200px, 100%)));
      }
    }
  }

  .annotation-box.selected {
    .count-circle {
      animation: neon 0.5s infinite alternate;
      color: #000;
      background-color: var(--selected-asset-glow);
    }
  }

  .annotation-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // Button and counter styles
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(8px, 1%);
  }

  .count-circle {
    width: min(20px, 1.67%);
    height: min(20px, 1.67%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: min(10px, 0.83%);
    cursor: pointer;
    border: 2px solid transparent;
    background-color: #f5f5f5;

    &.orange {
      background-color: #ffcc80;
    }

    &.light-blue {
      background-color: #81d4fa;
    }

    &.gold-border {
      border-color: gold;
    }

    // Zero count - orange
    background-color: #ffa500;

    // Active state (count > 0) - light blue
    &.active {
      background-color: #add8e6;
    }
  }

  .purple-button {
    width: min(30px, 2.5%);
    height: min(30px, 2.5%);
    padding: 0;
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: min(16px, 1.33%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 4;

    &:hover {
      background-color: #3700b3;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .toggle-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .toggle-button {
    padding: 8px 16px;
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #3700b3;
    }
  }

  // Title styles
  .tray_title {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  // Measurement text styles
  .rotated-measurement_text {
    position: absolute;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

    &.left {
      left: -30px;
      transform: rotate(-90deg);
      transform-origin: right center;
    }

    &.right {
      right: -30px;
      transform: rotate(90deg);
      transform-origin: left center;
    }
  }

  // Rod container styles (if needed)
  .rod_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    img {
      width: 40px;
      height: auto;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.4;
  }
  100% {
    transform: scale(1);
    opacity: 0.6;
  }
}
