.scanner-container {
  .body-container {
    height: calc(100vh - 190px);

    display: flex;

    .scanner {
      width: 100%;
      border-radius: 15px;

      .camera {
        z-index: 0;
        height: 400px;
        width: 708px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .instructions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        top: 48px;
        gap: 20px;
      }
    }

    .result {
      width: 100%;
      overflow: auto;
    }
  }
}

.scanner-container.is-tablet {
  .mobile-header {
    background-color: var(--light-blue-bg);
    position: relative;
    z-index: 100;

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px 20px 20px;
      top: 60px;

      .counter-flag {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .body-container {
    flex-direction: column;

    .scanner {
      .instructions {
        height: 400px;
      }
    }
  }
}
