.parent-details-box {
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: 100%;
  max-width: 100vw;

  .details-header-box {
    display: flex;
    gap: 8px;
    margin: 20px 30px 20px;
    justify-content: space-between;
  }

  .details-child-box {
    gap: 8px;
    margin: 0px 30px 20px;
    height: 80%;
  }
}
