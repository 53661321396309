.tray-management-layout_wrapper {
  padding: 0 !important;
  min-height: 100vh !important;
}

.step-layout {
  position: relative;
  top: 64px;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;

  .step-layout_header {
    position: relative;
  }

  .discard-exit-button {
    position: absolute;
    top: 34px;
    left: 16px;
  }

  .stepper {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 1.5rem 1.5rem 1.5rem 5rem;
    display: flex;
    gap: 1px;

    @media screen and (min-width: 1360px) {
      padding: 1.5rem;
    }

    .MuiStepConnector-root {
      display: none;
    }

    .MuiStep-root {
      padding: 0;
      margin: 0;
      position: relative;
      flex: 1;

      &:not(:first-child) {
        margin-left: -20px;
        z-index: 1;

        &.Mui-completed {
          z-index: 2;
        }
      }

      .MuiStepLabel-root {
        background-color: var(--grayscale-light);
        padding: 0 24px 0 48px;
        width: 100%;
        min-height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 0;
        position: relative;
        clip-path: polygon(
          0 0,
          calc(100% - 20px) 0,
          100% 50%,
          calc(100% - 20px) 100%,
          0 100%,
          20px 50%
        );

        &::after {
          content: '';
          position: absolute;
          right: 20px;
          top: 0;
          height: 100%;
          width: 1px;
          background: rgba(0, 0, 0, 0.1);
          z-index: 2;
        }
      }

      &:first-child .MuiStepLabel-root {
        border-radius: 8px 0 0 8px;
        clip-path: polygon(
          0 0,
          calc(100% - 20px) 0,
          100% 50%,
          calc(100% - 20px) 100%,
          0 100%
        );
        padding: 0 24px;
      }

      &:last-child .MuiStepLabel-root {
        border-radius: 0 8px 8px 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20px 50%);

        &::after {
          display: none;
        }
      }

      .MuiStepLabel-labelContainer {
        display: flex;
        align-items: center;
      }

      .MuiStepLabel-label {
        font-size: 0.6rem;

        @media screen and (min-width: 1200px) {
          font-size: 0.875rem;
        }
      }

      &:not(:last-child) .MuiStepLabel-root::after {
        display: none;
      }

      &.Mui-completed,
      &.Mui-active {
        z-index: 2;

        .MuiStepLabel-root::after {
          background: rgba(255, 255, 255, 0.2);
        }

        .MuiStepLabel-root {
          background-color: var(--primary);
          color: var(--white);

          &::before {
            background: rgba(255, 255, 255, 0.2);
          }
        }

        .MuiStepLabel-label {
          color: var(--white) !important;
        }

        .MuiStepIcon-root {
          color: var(--white);
        }
      }
    }

    .MuiSvgIcon-root text {
      fill: var(--white) !important;
    }

    .step-label {
      font-weight: 500;
    }

    .step-label-container {
      color: var(--grayscale-dark);
      cursor: pointer;
    }
  }

  .step-view {
    flex: 1;
    background-color: var(--grayscale-lightest);

    .step-container {
      max-width: 1200px;
      margin: 2rem auto 3rem;

      .header_wrapper {
        h1 {
          font-size: 20px;
          font-weight: 600;
        }
      }

      .buttons_container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        margin-top: 2rem;
      }
    }

    .step-item {
      padding: 2rem;
      min-height: 250px;
    }
  }
}
