.notification-container {
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  height: calc(75vh);
  overflow-y: auto;
  width: 400px;

  .notification-header {
    margin-bottom: 16px;
    color: #333;
    font-weight: bold;
  }

  .divider {
    margin-bottom: 16px;
  }

  .notification {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 12px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .notification-name {
      color: var(--primary);
      font-weight: bold;
      margin-bottom: 8px;
    }

    .notification-email,
    .notification-phone,
    .notification-status {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .status-success {
      color: var(--success);
    }

    .status-failure {
      color: var(--error);
    }

    .status-timestamp {
      color: var(--grayscale-contrast-text);
      font-size: 0.85rem;
      margin-left: 8px;
    }
  }

  .no-notifications {
    text-align: center;
    color: #888;
    font-style: italic;
  }

  &.is-mobile {
    width: calc(100vw - 75px);
  }
}
