.demoMenu {
  display: flex;
  flex-direction: column;
  margin-top: 8em;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em 0;
}

.buttonContainer > * {
  width: 45%;
}

.statusContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3em;
}
