.tray-case-details-organism_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  gap: 20px;
  padding: 0 40px;

  .filters_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .loader {
    align-self: center;
  }

  .surgery-list_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding-right: 20px;
    max-height: 600px;
    overflow-y: auto;
    border-radius: 15px;
  }
}
