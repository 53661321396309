.tray-management-filters_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-light);
  align-items: center;
  justify-content: flex-start;
  padding: 70px 20px 40px;
  gap: 20px;
  border-radius: 15px;
  position: fixed;
  left: -300px;
  width: 300px;
  height: 100%;
  transition: all 0.3s ease;
  z-index: 19;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
  }

  .input_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    input,
    input::placeholder,
    label,
    .MuiSvgIcon-root {
      color: white;
      opacity: 1;
    }

    .MuiTextField-root, .MuiSelect-root {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      padding: 8px;
      
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }

  .input_container {
    &:has(.tray-id-search) {
      margin-top: 20px;
    }
  }

  .add-tray_button {
    margin-top: auto;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.tray-management-filters_container.open {
  left: 0px;
}

.tray-filters {
  background: white;
  padding: 16px;
  
  .filter-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;
    background: white;
    border: 1px solid var(--grayscale-light);
    
    .filter-bar-left {
      display: flex;
      align-items: center;
      gap: 12px;
      flex: 1;
      
      .search-bar {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        gap: 8px;
        background: var(--grayscale-light);
        border-radius: 8px;
        width: 300px;
        
        .search-icon {
          color: var(--grayscale);
        }
        
        .MuiInputBase-root {
          font-size: 14px;
        }
      }
      
      .MuiButton-root {
        &.active {
          background: var(--primary-light);
          color: var(--primary);
        }
      }
    }
    
    .filter-bar-right {
      display: flex;
      align-items: center;
      gap: 12px;
      
      .MuiButton-contained {
        background: var(--primary);
        
        &:hover {
          background: var(--primary-dark);
        }
      }
    }
  }
  
  .active-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px 16px 0;
    
    .MuiChip-root {
      background: white;
      border-color: var(--primary-light);
      color: var(--primary);
      
      .MuiChip-deleteIcon {
        color: var(--primary);
        
        &:hover {
          color: var(--primary-dark);
        }
      }
    }
  }
}

.filter-drawer {
  .MuiDrawer-paper {
    width: 320px;
    padding: 16px;
  }
  
  .filter-drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .filter-drawer-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 0;
    height: calc(100% - 64px);
    
    .filter-section {
      display: flex;
      flex-direction: column;
      gap: 12px;
      
      .MuiFormGroup-root,
      .MuiRadioGroup-root {
        padding-left: 8px;
        
        .MuiFormControlLabel-root {
          margin: 4px 0;
          
          .MuiRadio-root,
          .MuiCheckbox-root {
            padding: 6px;
            
            &:hover {
              background: rgba(0, 0, 0, 0.04);
            }
          }
        }
      }
    }

    .filter-actions {
      margin-top: auto;
      padding: 16px 0;
      border-top: 1px solid var(--grayscale-light);
    }
  }
}

@media (max-width: 768px) {
  .tray-filters {
    .filter-bar {
      flex-direction: column;
      align-items: stretch;
      
      .filter-bar-left {
        flex-direction: column;
        
        .search-bar {
          width: 100%;
        }
      }
      
      .filter-bar-right {
        justify-content: space-between;
      }
    }
  }
}
