.share-product-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
  width: 100%;
  padding: 0 1rem 0.5rem 1rem;

  &.reps-data-exist {
    justify-content: space-between;
    flex-direction: column;
    max-height: 252px;
    overflow: auto;
  }

  .rep-container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    padding: 0.75rem;
    border-top: 1px solid #e0e0e0;

    .info-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      .email-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 3px 8px;
        border-radius: 4px;
        width: 392px;
        background-color: var(--primary-light);

        &.is-received {
          background-color: var(--success-light);
        }
      }
    }
  }
}
