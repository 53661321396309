.addAssetManuallyButtonContainer {
  position: absolute;
  bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
}

.scanInstructions {
  position: fixed;
  top: 100px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.paperModalButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.paperModalContent {
  display: flex;
}

.paperModalMessage {
  line-height: 1.313;
}

.queryLoadingContent {
  display: flex;
  align-items: center;
}

.videoContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.video {
  object-fit: cover;
}
