.root {
  background-color: #efecfd;
  margin: 1em 0;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0.75em;
}

.formControl {
  margin-top: 0;
}

.successMessage {
  align-self: center;
  color: #1d4622;
  display: flex;
  text-transform: uppercase;
}
