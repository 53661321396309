.multi-scanner-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary-light);
      padding: 40px;
      border-radius: 50%;

      &:hover {
        transition: all 0.3s;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .result-container {
    display: flex;
    align-items: flex-start;
    gap: 30px;

    .image {
      margin-top: 20px;
      img {
        height: 100px;
        width: auto;
        object-fit: cover;
        border-radius: 7px;
      }
    }
    .codes {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .code-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 4px;
        .code {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          padding: 10px;
          background-color: var(--primary-light);
          border-radius: 5px;

          > * {
            margin-bottom: 5px;
          }
        }
      }
    }

    .remove {
      cursor: pointer;
    }
  }
}

.multi-scanner-container.is-tablet {
  .result-container {
    flex-direction: column;
    align-items: start;
    gap: 20px;

    .codes {
      align-items: start;
      .code {
        h4 {
          max-width: 320px;
          overflow: hidden;
        }
      }
    }
  }
}
