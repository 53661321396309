.custom-modal {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parent-modal-box {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-height: 90%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 1em;
  border-radius: 8px;

  .select-all-box-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    text-transform: capitalize;
  }

  .subheader-box {
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    align-items: center;
    margin-left: 3em;
  }

  .subheader-box.is-mobile {
    margin-left: 1em;
  }

  .child-box {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    max-height: 70%;
  }

  .analyze-state {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: column;
    justify-content: center;
  }

  &.fit-content {
    max-width: fit-content;
  }
}

.parent-modal-box.is-mobile {
  width: 90%;
}
