:root {
  /* Primary Palette */
  --primary: #5035ed;
  --primary-light: #dfd9fc;
  --primary-lightest: #efecfd;
  --primary-dark: #0c0e35;
  --primary-hover: #beb4f8;
  --primary-disabled: #9e8ef5;
  --primary-contrast-text: #190a71;

  /* Secondary Palette */
  --secondary: #4fa4e1;
  --secondary-light: #dcedf9;
  --secondary-lightest: #eef6fc;
  --secondary-dark: #185b8c;
  --secondary-hover: #b9dbf3;
  --secondary-disabled: #96c9ed;
  --secondary-contrast-text: #124469;

  /* Error Palette */
  --error: #ea4848;
  --error-light: #fbdada;
  --error-lightest: #fdeded;
  --error-dark: #921111;
  --error-hover: #f6b6b6;
  --error-disabled: #f29191;
  --error-contrast-text: #6e0d0d;

  /* Success Palette */
  --success: #42b348;
  --success-light: #b5e3b7;
  --success-lightest: #e1f4e2;
  --success-dark: #1c4a1e;
  --success-hover: #97d89a;
  --success-disabled: #79cd7d;
  --success-contrast-text: #112d12;

  /* Warning Palette */
  --warning: #ee6c01;
  --warning-light: #fec79a;
  --warning-lightest: #ffe9d6;
  --warning-dark: #652e01;
  --warning-hover: #feb171;
  --warning-disabled: #fe9a48;
  --warning-contrast-text: #3d1c00;

  /* Grayscale Palette */
  --grayscale: #999999;
  --grayscale-light: #ebebeb;
  --grayscale-lightest: #f5f5f5;
  --grayscale-dark: #525252;
  --grayscale-hover: #d6d6d6;
  --grayscale-disabled: #c2c2c2;
  --grayscale-contrast-text: #3d3d3d;

  /* Safety Palette */
  --safety: #fde220;
  --safety-light: #fef7c2;
  --safety-lightest: #fffad7;
  --safety-dark: #8d7d01;
  --safety-hover: #fef29a;
  --safety-disabled: #feed72;
  --safety-contrast-text: #655901;

  /* Screws */
  --screw-drilling: #e2dca8;
  --screw-emergency: #b13b80;
  --screw-bone: #92c05b;
  --screw-highlight: #36fff8;

  /* Trays */
  --tray-background: #af9164;
  --zimmer-rod-background: #DDDED8;
  --selected-asset-glow: #36fff8;
  --active-screw-background: #81d4fa;
  --empty-screw-background: #ffcc80;

  /* Typography Sizes */
  --font-size-h1: 1.5rem;
  --font-size-h2: 1.25rem;
  --font-size-h3: 1rem;
  --font-size-h4: 0.875rem;
  --font-size-h5: 0.875rem;
  --font-size-h6: 0.875rem;
  --font-size-subtitle2: 0.75rem;

  /* Typography Line Heights */
  --line-height-h1: 2.04;
  --line-height-h4: 1.75;
  --line-height-h5: 1.2;
  --line-height-h6: 1.2;
  --line-height-subtitle2: 1.5;

  /* Typography Weights */
  --font-weight-h1: 700;
  --font-weight-h2: 700;
  --font-weight-h3: 500;
  --font-weight-h4: 500;
  --font-weight-h5: 500;
  --font-weight-h6: 400;
  --font-weight-subtitle2: 400;

  /* TODO: phase out the following properties and use above */
  --white: #ffffff;
  --blue: var(--secondary-dark);
  --red: var(--error);
  --green: var(--success);
  --text-black: var(--grayscale-contrast-text);
  --lupine-blue: var(--secondary-contrast-text);
  --light-blue: var(--secondary-disabled);
  --light-blue-2: var(--secondary-dark);
  --light-blue-bg: var(--secondary-lightest);
  --gray-1: var(--grayscale-lightest);
  --gray-2: var(--grayscale-hover);
  --gray-3: #a4aeb4;
  --gray-4: var(--grayscale-lightest);
  --gray-5: var(--grayscale);
  --gray-6: var(--grayscale-contrast-text);
  --gray-8: var(--grayscale);
  --gray-9: var(--grayscale-dark);
  --gray-10: var(--grayscale-light);
  --light-red-bg: var(--error-lightest);
  --light-red: var(--error-dark);
}

/* Text Styles */
h1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
  line-height: var(--line-height-h1);
  font-family: var(--font-family);
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
  font-family: var(--font-family);
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-h3);
  font-family: var(--font-family);
}

h4 {
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-h4);
  line-height: var(--line-height-h4);
  font-family: var(--font-family);
}

h5 {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-h5);
  line-height: var(--line-height-h5);
  font-family: var(--font-family);
}

h6 {
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-h6);
  line-height: var(--line-height-h6);
  font-family: var(--font-family);
}

.subtitle2 {
  font-size: var(--font-size-subtitle2);
  font-weight: var(--font-weight-subtitle2);
  line-height: var(--line-height-subtitle2);
  font-family: var(--font-family);
}
