.rep-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed var(--grayscale);

  &:is(:last-child) {
    border-bottom: none;
  }

  .details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 5px;
    width: 100%;

    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      margin-bottom: 10px;
      width: 100%;

      .detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 7px 10px;
          border-radius: 5px;
          background: var(--primary-light);
          width: 50%;

          &.is-email-sent {
            background: var(--success-light);
          }
          &.is-text-sent {
            background: var(--success-light);
          }
        }
      }
    }
  }

  &.is-mobile {
    .details-container {
      .details {
        .detail {
          .text {
            overflow-x: hidden;
            width: 100%;
          }
        }
      }
    }
  }
}
