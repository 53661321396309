.counter-container {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  width: 100%;
  padding: 0;

  .counter-label {
    margin-bottom: 6px;
    font-size: 13px;
    color: var(--primary-dark);

    &.active {
      color: var(--primary);
    }
  }

  .MuiButtonGroup-root {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .button {
    color: var(--primary-dark);
    background-color: var(--primary-light);
    border-color: var(--grayscale);
    height: 40px;
    &:hover {
      background-color: var(--primary);
      border-color: var(--grayscale);
      color: var(--white);
    }
  }

  .input {
    .MuiOutlinedInput-root {
      fieldset {
        border-radius: 0;
        border-color: var(--grayscale);
      }
      &:hover {
        border-color: var(--grayscale);
      }
      &.Mui-focused {
        border-color: var(--primary-light);
      }
      input {
        text-align: center;
        color: var(--primary-dark);
        width: 100%;
      }
    }
  }
}
