.surgery-box-molecule_wrapper {
  width: 100%;
  padding: 10px 20px;
  border-radius: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  border: 1px solid var(--gray-10);
  border-radius: 15px;

  .details {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .room {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      background-color: var(--primary);
      color: var(--white);
      padding: 0.5rem;
      gap: 0.5rem;
      height: 100%;
      width: 40%;
    }

    .patient {
      flex-direction: column;
      display: flex;
      width: 50%;
    }
  }

  button {
    width: auto;
  }
}

@media screen and (width < 700px) {
  .surgery-box-molecule_wrapper {
    height: auto;
    flex-direction: column;
    gap: 20px;

    .details {
      width: 100%;

      .room {
        height: 100%;
        min-height: 100%;
      }
    }

    button {
      width: 100%;
    }
  }
}

.surgery-box-molecule_wrapper.selected {
  border: 3px solid var(--primary);
}
