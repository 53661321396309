.implant-report-sender-container {
  background-color: var(--primary-lightest);
  margin: 1em 0;
  border: 1px solid var(--grayscale-hover);
  border-radius: 4px;
  padding: 0.75em;

  .implant-report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .implant-header-title {
      flex-direction: column;
    }
  }

  .secondary-text {
    margin-top: 0.5em;
    color: rgba(0, 0, 0, 0.6);
  }

  .referring-input {
    display: flex;
    align-items: center;
    column-gap: 1em;

    .physician-container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 5px;
    }

    &.is-mobile {
      flex-wrap: wrap;
    }
  }

  .implant-report-menu {
    margin-top: 0.5em;
    margin-bottom: 0;
  }
}

.implant-report-header-no-options {
  flex-direction: column;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.extraction-report-input-with-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;

  .add-icon {
    color: var(--primary);
  }

  .extraction-report-input-array {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0.5em;
  }
}

.first-single-stage {
  .next-date-picker {
    margin: 0.5em;
  }

  .next-appt-input {
    margin-top: 1.25em;
    margin-bottom: 0.5em;
  }

  .other-abutment-input {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
}

.restorative-report-input-array {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 0.5em;

  .restorative-date-picker {
    margin-bottom: 0.5em;
  }

  .or-typography {
    margin: 0.5em;
  }
}

.view-reports-modal {
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid var(--grayscale-hover);
  overflow: auto;
}

.attachment-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-dark);
  margin-top: 1rem;

  .attachment-images-wrapper {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .attachment-image-box {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      position: relative;

      .attachment-image {
        height: 70px;
        cursor: pointer;
      }

      .delete-attachment {
        background-color: var(--primary);
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        width: 20px;
        height: 20px;

        .close {
          color: #f5f5f5;
          font-size: 15px;
        }

        &:hover {
          width: 25px;
          height: 25px;
        }

        &:hover > :first-child {
          font-size: 20px;
        }
      }
    }
  }

  .titles {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;

    &.ellipsis {
      max-width: 70px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.attachement-container-reports {
  display: flex;
  gap: 1rem;
  min-height: 70px;

  .attachment-title {
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    align-items: center;
    margin-left: 3em;

    &.isMobile {
      margin-left: 1em;
    }
  }

  .attachment-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .attachment-box {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      justify-content: space-between;

      a {
        height: fit-content;
        width: fit-content;
      }

      .attachment-link {
        font-size: 14px;
        text-decoration: underline;
        color: var(--primary-contrast-text);
        cursor: pointer;
        overflow: hidden;
        max-width: 70px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .attachment-image {
        height: 70px;
        cursor: pointer;
      }

      .pdf-preview {
        height: 70px;
        aspect-ratio: 1;
        border: 1px solid #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}
