.static-skeleton {
  background: var(--gray-4);
  padding: 21px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 12px;

  .long-space {
    background: white;
    padding: 8px;
    width: 75%;
    border-radius: 3px;
  }
  .bottom {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    .short-space {
      background: white;
      width: 25%;
      padding: 8px;
      border-radius: 3px;
    }
  }
}
