.tray-management_wrapper {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  align-self: center;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  padding: 0 24px;

  .loading_wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
  }

  .table_container {
    width: 100%;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    overflow-y: auto;
    padding: 0 24px 24px;
    border: 1px solid var(--grayscale-light);
    border-top: none;

    .tray-icon-svg_container {
      width: 120px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--grayscale);

      .tray-icon_svg {
        width: 100%;
        object-fit: cover;
        border-radius: inherit;
      }
    }

    .tray-icon-svg_container.facility {
      background-color: var(--success);
    }
    .tray-icon-svg_container.loaner {
      background-color: var(--secondary-light);
    }
    .tray-icon-svg_container.consigned {
      background-color: var(--secondary-dark);
    }

    .tray-icon-svg_container.facility,
    .tray-icon-svg_container.consigned {
      .tray-icon_svg {
        color: white;
      }
    }

    .case-number_container {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      button {
        width: auto;
        min-width: 10px;
      }

      .un-assigned {
        background-color: var(--safety-disabled);
        color: var(--safety-contrast-text);
        padding: 5px 10px;
        border-radius: 5px;
      }

      .case-information_wrapper {
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
      }
    }

    .tray-status_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .tray-status_text {
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
      }

      &.approved .tray-status_text {
        background-color: var(--success);
      }

      .tray-status_text.approved {
        background-color: var(--success) !important;
        margin-bottom: 10px;
        display: block;
      }

      &.rejected .tray-status_text {
        background-color: var(--error);
      }

      &.canceled .tray-status_text {
        background-color: var(--warning);
      }

      &.readyForPickup .tray-status_text {
        background-color: var(--info);
      }

      &.caseComplete .tray-status_text {
        background-color: var(--success-dark);
      }

      &.assigned .tray-status_text {
        background-color: var(--primary);
      }

      &.readyToFill .tray-status_text {
        background-color: var(--secondary);
      }

      &.droppedOff .tray-status_text {
        background-color: var(--info-dark);
      }

      &.droppedOff .tray-status_text {
        background-color: var(--grayscale-dark);
      }

      &.checkedOut .tray-status_text {
        background-color: var(--secondary-dark);
      }

      &.loaded .tray-status_text {
        background-color: var(--success);
      }

      &.readyForPickup .tray-status_text {
        background-color: var(--warning-dark);
      }

      &.unknown .tray-status_text {
        background-color: var(--success-contrast-text);
      }

      &.unAssigned .tray-status_text {
        background-color: var(--safety-disabled);
        color: var(--safety-contrast-text);
      }

      button {
        margin: 10px 0;
      }
    }
  }

  .tray-filters {
    width: 100%;
    background: white;
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--grayscale-light);
    border-bottom: none;
    margin-bottom: -1px;
  }

  @media screen and (width < 700px) {
    padding: 0 12px;
    margin-top: 24px;

    .table_container {
      padding: 0 12px 12px;
    }
  }
}
