.custom-button {
  text-transform: none !important;

  &.primary {
    background: var(--light-blue-bg);
  }
  &.error {
    background: var(--red);
  }
  &.warning {
    background: var(--light-red);
  }
  &:disabled {
    background: var(--gray-1);
    color: var(--gray-3);
  }
}
