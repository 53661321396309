.loading-img_container {
  position: relative;

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    .icon {
      width: 30px;
      height: 30px;
    }
  }
}
