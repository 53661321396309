.usage-reports-container {
  display: flex;
  flex-direction: column;
  gap: 7px;

  .report-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
