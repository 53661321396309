.select-tray-id-modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 500px;
  max-height: 95%;
  background-color: var(--primary-lightest);
  box-shadow: 24px;
  padding: 1em;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;

  .header-box {
    display: flex;
    align-items: center;
    column-gap: 1em;
    flex-direction: row;
    color: var(--primary-contrast-text);
    line-height: 1.5em;
    text-transform: capitalize;
  }

  .scanner-container {
    width: 100%;
    height: 260px;
    border-radius: 25px;
    position: relative;
    margin: 20px 0;

    .skeleton {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 13;
    }
  }

  .product-container {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }
}

@media screen and (max-width: 800px) {
  .select-tray-id-modal {
    width: 95%;
  }
}
